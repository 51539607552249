import * as S from "./styles";

const PrivacyPolicy = () => {
  return (
    <S.PrivacyPolicyWrapper>
      <S.Container>
        <S.Contain>
          <h1>Privacy Policy</h1>
          <div className="text-white">Effective Date: May 1, 2024</div>
          <div className="text-white mt-2 font-thin">
            Fanxo, a platform owned and operated by Mave Technologies, provides
            a space for users to engage with unique content, either as creators
            ("Creators") or consumers ("Fans"). Your privacy is essential to us,
            and this Privacy Policy explains how Fanxo ("we," "our," or "us")
            collects, uses, stores, and shares your personal data when you visit
            our site at www.fanxo.club or its associated sites and services (the
            "Services"). We encourage you to read this policy carefully to
            understand how we handle your information.
          </div>
          <div className="text-white my-2 font-semibold">Data We Collect</div>
          <div className="text-white mb-2 font-semibold">
            Personal Information Provided by You
          </div>
          <div className="text-white mt-2 font-thin">
            We collect data that you voluntarily provide to us when using our
            Services. This may include:{" "}
          </div>
          <div className="text-white mt-2 font-thin">
            Contact Information: Your name, email address, mailing address, and
            phone number.
          </div>
          <div className="text-white mt-2 font-thin">
            Account Details: Information like your username, password, profile
            photo, bio, links to social media profiles, non-fungible token (NFT)
            ownership, digital wallet addresses, and any other data associated
            with your account.
          </div>
          <div className="text-white mt-2 font-thin">
            Communications: Information from messages, feedback, and inquiries
            sent through our platform or other channels.
          </div>
          <div className="text-white mt-2 font-thin">
            Payment Information: Details required to process transactions, such
            as credit card information, digital wallet details, and transaction
            history.
          </div>
          <div className="text-white mt-2 font-thin">
            User-Generated Content: Content like posts, comments, images, and
            videos shared on our platform, including associated metadata.
          </div>
          <div className="text-white mt-2 font-thin">
            Government Identification: For purposes like identity verification
            and regulatory compliance, we may collect information such as
            national identification numbers, tax IDs, or other official
            documents.
          </div>
          <div className="text-white my-2 font-semibold">Third-Party Data</div>
          <div className="text-white mt-2 font-thin">
            We may gather information about you from third-party sources,
            including:
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">Public Sources: </span>
            Information from publicly available resources such as social media
            profiles.
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">Data Providers: </span>
            Companies that provide demographic and related data.
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">
              Service Providers:{" "}
            </span>
            Businesses that assist with user verification, fraud prevention, or
            enhancing user experience.
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">Linked Accounts: </span>
            If you connect your Fanxo account with third-party platforms (such
            as Facebook, Google, or Twitter), we may collect data from those
            services, including your profile name, avatar, and any other shared
            details.
          </div>
          <div className="text-white my-2 font-semibold">
            Data Collected Automatically
          </div>
          <div className="text-white mt-2 font-thin">
            We automatically collect certain data about your interactions with
            our Services, including:
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">
              Device Information:{" "}
            </span>
            Details about your device, such as the operating system, browser
            type, hardware specifications, IP address, and general location
            (e.g., city or region).
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">Usage Data: </span>
            Information about how you interact with our website, such as pages
            visited, links clicked, time spent on each page, and navigation
            patterns.
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">
              Cookies and Similar Technologies:{" "}
            </span>
            We use cookies, pixels, tags, and other tracking technologies to
            enhance your experience, improve our Services, and deliver
            personalized content. For more information on our use of cookies and
            how to manage them, refer to our [Cookie Policy].
          </div>
          <div className="text-white my-2 font-semibold">Data About Others</div>
          <div className="text-white mt-2 font-thin">
            You may refer others to Fanxo by providing their contact
            information. Please ensure that you have their consent before
            sharing such information with us.
          </div>
          <div className="text-white my-2 font-semibold">
            How We Use Your Data
          </div>
          <div className="text-white mt-2 font-thin">
            We use the personal data we collect for the following purposes:
          </div>
          <div className="text-white my-2 font-semibold">Service Provision</div>
          <div className="text-white mt-2 font-thin">
            We use your personal data to:
          </div>
          <div className="text-white pl-2 mt-2 font-thin">
            <div className="text-white font-thin">
              - Create and manage your account.
            </div>
            <div className="text-white font-thin">
              - Facilitate transactions and process payments.
            </div>
            <div className="text-white font-thin">
              - Deliver our Services and ensure functionality, such as managing
              content uploads and subscriptions.
            </div>
            <div className="text-white font-thin">
              - Improve the quality and security of our platform by identifying
              fraudulent activities, managing digital wallets, and
              authenticating users.
            </div>
            <div className="text-white font-thin">
              - Communicate with you about your account, service updates, and
              other relevant matters.
            </div>
          </div>
          <div className="text-white my-2 font-semibold">
            Personalization and User Experience
          </div>
          <div className="text-white mt-2 font-thin">
            Your data helps us understand your preferences and provide you with
            a tailored experience. We may analyze your activity on our platform
            to deliver personalized recommendations, advertisements, or other
            content.
          </div>
          <div className="text-white my-2 font-semibold">
            Marketing and Promotions
          </div>
          <div className="text-white mt-2 font-thin">
            With your consent, we may use your data to send marketing emails,
            promotional content, and other communications. You can manage your
            preferences by unsubscribing through the provided links or adjusting
            your account settings.
          </div>
          <div className="text-white my-2 font-semibold">
            Research and Development
          </div>
          <div className="text-white mt-2 font-thin">
            We may use personal data to analyze trends, understand user
            behavior, and improve our services. This includes generating
            anonymized or aggregated data, which we may share with third parties
            for business or marketing purposes.
          </div>
          <div className="text-white my-2 font-semibold">
            Compliance with Legal Obligations
          </div>
          <div className="text-white mt-2 font-thin">
            In some cases, we may be required to use or share your data to
            comply with applicable laws, regulations, or legal processes. This
            could include responding to law enforcement requests or protecting
            our platform from fraud, identity theft, or other criminal
            activities.
          </div>
          <div className="text-white my-2 font-semibold">
            Aggregated and Anonymized Data
          </div>
          <div className="text-white mt-2 font-thin">
            We may create de-identified, anonymized, or aggregated data from
            your personal information. This data will not be traceable back to
            you and may be used for purposes such as improving our platform,
            developing new services, and conducting market research.
          </div>
          <div className="text-white my-2 font-semibold">
            How We Share Your Data
          </div>
          <div className="text-white mt-2 font-thin">
            Fanxo does not sell or rent your personal information to third
            parties for marketing purposes without your explicit consent.
            However, we may share your data in the following situations:
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">Affiliates: </span>
            We may share your information with our parent company, subsidiaries,
            and other affiliated entities to facilitate our Services.
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">
              Service Providers:{" "}
            </span>
            Data may be shared with third-party service providers who help us
            operate our Services (e.g., payment processing, website hosting,
            customer support, and email marketing).
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">
              Payment Processors and Fraud Detection:{" "}
            </span>
            Personal data is shared with payment processors and security
            services to prevent fraud, authorize transactions, and ensure
            compliance with applicable laws.
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">
              Advertising Partners:{" "}
            </span>
            We may share data with third-party advertising companies to display
            interestbased ads. You can control some of this tracking through
            browser settings and industry opt-out tools.
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">Creators: </span>
            If you subscribe to a Creator's content, your email address and
            other relevant data may be shared with the Creator.
          </div>
          <div className="text-white mt-2 font-thin">
            <span className="text-white font-semibold">
              Legal and Regulatory Authorities:{" "}
            </span>
            We may share your data with law enforcement agencies, public
            authorities, or other organizations if we are legally obligated to
            do so, or if such sharing is necessary to protect our legal rights
            or prevent illegal activities.
          </div>
          <div className="text-white my-2 font-semibold">
            Your Rights and Choices
          </div>
          <div className="text-white mt-2 font-thin">
            You have several rights regarding your personal data:
          </div>
          <div className="text-white my-2 font-semibold">
            Access and Updates
          </div>
          <div className="text-white mt-2 font-thin">
            You can review and update your account information at any time by
            logging into your account.
          </div>
          <div className="text-white my-2 font-semibold">Data Deletion</div>
          <div className="text-white mt-2 font-thin">
            You may request the deletion of your data by closing your account
            through your Account Settings. Please note that certain data may be
            retained for legal or business purposes.
          </div>
          <div className="text-white my-2 font-semibold">
            Opt-Out of Marketing
          </div>
          <div className="text-white mt-2 font-thin">
            You can opt out of receiving marketing communications by following
            the unsubscribe link provided in our emails or by adjusting your
            preferences in your account.
          </div>
          <div className="text-white my-2 font-semibold">
            Cookies and Tracking
          </div>
          <div className="text-white mt-2 font-thin">
            You can manage your cookie preferences through your browser settings
            or by using tools such as ad blockers or privacy-focused browser
            plugins.
          </div>
          <div className="text-white my-2 font-semibold">Data Security</div>
          <div className="text-white mt-2 font-thin">
            Fanxo takes appropriate technical and organizational measures to
            safeguard your personal data against unauthorized access, loss,
            misuse, alteration, or destruction. While we strive to protect your
            information, no method of online transmission is completely secure,
            and we cannot guarantee absolute security. We encourage users to
            protect their accounts by using strong passwords and safeguarding
            their login credentials.
          </div>
          <div className="text-white my-2 font-semibold">
            International Data Transfers
          </div>
          <div className="text-white mt-2 font-thin">
            We are based in India, and your personal data may be transferred to
            and processed in other countries. If we transfer your data across
            borders, we will take steps to ensure that appropriate safeguards
            are in place as required by law.
          </div>
          <div className="text-white my-2 font-semibold">
            Children’s Privacy
          </div>
          <div className="text-white mt-2 font-thin">
            Our Services are not intended for individuals under the age of 16.
            We do not knowingly collect personal data from children under 16
            without verified parental consent. If you are between the ages of 16
            and 18 and wish to use our Services, you may do so only with the
            prior approval and supervision of a parent or guardian. If we learn
            that we have collected personal data from a minor without such
            consent, we will take steps to delete the information.
          </div>
          <div className="text-white my-2 font-semibold">
            Changes to this Privacy Policy
          </div>
          <div className="text-white mt-2 font-thin">
            We may update this Privacy Policy from time to time to reflect
            changes in our practices or applicable laws. When we make
            significant changes, we will notify you by updating the effective
            date of this policy and providing other forms of notice as
            appropriate. We encourage you to review this policy periodically to
            stay informed about how we are protecting your information.
          </div>
          <div className="text-white my-2 font-semibold">Contact Us</div>
          <div className="text-white mt-2 font-thin">
            If you have any questions, concerns, or requests regarding your
            personal data, please contact us at:
          </div>
          <div className="text-white my-2 font-bold">Fanxo</div>
          <div className="text-white my-2 font-semibold">
            Email: <a target="__blank" href="emmailto:michael@domain.com" className="underline underline-offset-2">business@fanxo.club</a>
          </div>
        </S.Contain>
      </S.Container>
    </S.PrivacyPolicyWrapper>
  );
};

export default PrivacyPolicy;
