import React, { useState } from "react";
import * as A from "./styled";
import * as interFace from "../../Config/interface.config";
import * as C from "../../common-styles";
import { useNavigate, useParams } from "react-router-dom";
import { AiOutlineEye } from "react-icons/ai";
import { TbEyeClosed } from "react-icons/tb";
import { Formik, Form } from "formik";
import * as validation from "../../Config/validation.config";
import * as APIPATHS from "../../API/path";
import APIRequest from "../../API";
import toast from "react-hot-toast";

const RestPassword: React.FC = () => {
  const { token } = useParams<{ token: string }>();
  const navigate = useNavigate();
  const [formData] = useState<interFace.ResetPasswordInterface>({
    newPassword: "",
    confirmPassword: "",
  });

  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [showConfirmPassword, setShowConfirmPassword] =
    useState<boolean>(false);

  const onResetPassword = async (values: interFace.ResetPasswordInterface) => {
    const path: any = APIPATHS.resetPassword;
    path.url = path.url.replaceAll("{{token}}", token);
    const payload = {
      password: values.newPassword,
    };
    // Your reset password logic goes here
    const promise = APIRequest(path, payload);

    toast.promise(promise, {
      loading: "Resetting your password...",
      success: (data: any) => {
        if (data.error) throw new Error(data.error.message);
        navigate("/");
        return "Password reset successfully.";
      },
      error: (err) => {
        return err.message;
      },
    });
  };
  const toggleShowNewPassword = () => setShowNewPassword((prev) => !prev);
  const toggleShowConfirmPassword = () =>
    setShowConfirmPassword((prev) => !prev);

  return (
    <A.ChantPasswordWrapper>
      <A.ChangePassword>
        <h1 className="text-white font-noto flex items-center gap-5 md:gap-10">
          <span>Reset Password</span>
        </h1>
        <A.AccountLinkWrapper>
          <Formik
            initialValues={formData}
            onSubmit={onResetPassword}
            validationSchema={validation.resetPasswordValidation}
          >
            {({
              values,
              errors,
              touched,
              handleChange,
              handleBlur,
              isSubmitting,
            }) => (
              <Form className="reset-form-tag">
                <div className="reset-password-form">
                  <div>
                    <C.IconInputWrapper>
                      <C.CommonInput
                        placeholder="New Password"
                        name="newPassword"
                        type={!showNewPassword ? "password" : "text"}
                        value={values.newPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showNewPassword ? (
                        <AiOutlineEye
                          onClick={toggleShowNewPassword}
                          className="input-icon"
                          size={18}
                        />
                      ) : (
                        <TbEyeClosed
                          onClick={toggleShowNewPassword}
                          className="input-icon"
                          size={18}
                        />
                      )}
                    </C.IconInputWrapper>
                    <p className="text-white mt-0.5">
                      {errors.newPassword &&
                        touched.newPassword &&
                        errors.newPassword}
                    </p>
                  </div>
                  <div>
                    <C.IconInputWrapper>
                      <C.CommonInput
                        placeholder="Confirm New Password"
                        name="confirmPassword"
                        type={!showConfirmPassword ? "password" : "text"}
                        value={values.confirmPassword}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                      {showConfirmPassword ? (
                        <AiOutlineEye
                          onClick={toggleShowConfirmPassword}
                          className="input-icon"
                          size={18}
                        />
                      ) : (
                        <TbEyeClosed
                          onClick={toggleShowConfirmPassword}
                          className="input-icon"
                          size={18}
                        />
                      )}
                    </C.IconInputWrapper>
                    <p className="text-white mt-0.5">
                      {errors.confirmPassword &&
                        touched.confirmPassword &&
                        errors.confirmPassword}
                    </p>
                  </div>

                  <div>
                    <C.CommonButton
                      className="submit-button"
                      variant="primary"
                      type="submit"
                      disabled={isSubmitting}
                    >
                      Reset Password
                    </C.CommonButton>
                  </div>
                </div>
              </Form>
            )}
          </Formik>
        </A.AccountLinkWrapper>
      </A.ChangePassword>
    </A.ChantPasswordWrapper>
  );
};

export default RestPassword;
