import React, { useEffect, useRef, useState } from "react";
import { FeedPosts } from "../../Config/interface.config";
import * as P from "./style";
import { PiPlayCircle } from "react-icons/pi";

interface Props {
  url: string;
}

const VideoPlayer = ({ url }: Props) => {
  const videoRef = useRef<HTMLVideoElement>(null); // Create a ref for the video element
  const [error, setError] = useState<boolean>(false);
  const [isPlaying, setIsPlaying] = useState<boolean>(false);

  const handlePlay = (flag?: boolean) => {
    try {
      if (videoRef.current) {
        if (isPlaying) {
          videoRef.current.pause();
        } else {
          videoRef.current.play();
        }
        if (!flag) setIsPlaying(!isPlaying);
      }
    } catch (error) {

    }
  };

  useEffect(() => {
    return () => {
      if (videoRef.current)
        videoRef.current.pause();
    }
  }, [])
  return (
    <>

      <div className="w-full items-center justify-center" style={{
        height: '99%',
        background: 'black',
        border: '2px solid white',
        borderRadius: '10px',
        position: 'relative',
        zIndex: 99999,
        display: !error ? "none" : "flex"
      }}>
        <p className="text-white">Video not available, please subscribe.</p>
      </div>

      <P.PostVideo
        style={{ display: error ? "none" : "block" }}
        ref={videoRef}
        crossOrigin="anonymous"
        controls
        controlsList="nodownload"
        onPlay={() => handlePlay()}
        onPause={() => handlePlay()}
        onError={(error) => {
          if (videoRef.current) videoRef.current.remove();
          setError(true)
        }}
      >
        <source src={url} type="video/mp4"></source>
      </P.PostVideo>

      {
        !isPlaying && (
          <PiPlayCircle onClick={() => handlePlay(true)} className="play-icon" />
        )
      }
    </>
  );
};

export default VideoPlayer;
