import { SingleComment } from "../../Config/interface.config";
import { IoMdHeartEmpty, IoIosHeart } from "react-icons/io";
import userImg from "../../Assets/Images/user-profile.png";
// import { updatePostLike } from '../../Redux/slices/postSlice';
import * as C from "./styled";
import { postAndCommentLinkHandler } from "../../Config/common.config";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Capitalize } from "../../Utils";

interface Props {
  comment: SingleComment;
  postId: string;
}

const CommentSection = ({ comment, postId: post_id }: Props) => {
  const user = useSelector((state: RootState) => state.user);
  const [liked, setLiked] = useState<boolean>(false);

  const onCommentLike = async () => {
    if (!post_id || typeof user.id !== "string") return;
    setLiked(!liked);
    postAndCommentLinkHandler({ comment: comment._id, likedBy: user.id });
  };

  useEffect(() => {
    if (!comment || typeof user?.id !== "string") return;

    const likedByMe = comment.likeCount.find(
      (like) => like.likedBy._id === user.id
    );
    if (!likedByMe) return;

    setLiked(true);
  }, [comment]);

  return (
    <>
      {
        <C.Comment key={`comment-${comment._id}`}>
          <div className="flex justify-between flex-row">
            <C.CommentFlex>
              <C.UserImg
                src={
                  comment.commentBy?.image?.fileURL
                    ? comment.commentBy?.image?.fileURL
                    : userImg
                }
                alt="user"
                crossOrigin="anonymous"
              />
              <div className="flex flex-col">
                <C.CommentText>
                  {comment.commentBy.username
                    ? Capitalize(comment.commentBy.username)
                    : Capitalize(comment.commentBy.fullname)}
                </C.CommentText>
                <C.CommentText>{comment.text}</C.CommentText>
              </div>
            </C.CommentFlex>
            <div onClick={onCommentLike}>
              {liked ? (
                <C.CommentLike>
                  <IoIosHeart
                    size={20}
                    fill="#ff0000"
                    className="cursor-pointer"
                  />
                  <span>{comment.likeCount.length}</span>
                </C.CommentLike>
              ) : (
                <C.CommentLike>
                  <IoMdHeartEmpty
                    size={20}
                    fill="#fff"
                    className="cursor-pointer"
                  />
                  <span>{comment.likeCount.length}</span>
                </C.CommentLike>
              )}
            </div>
          </div>
        </C.Comment>
      }
    </>
  );
};

export default CommentSection;
