import styled from "styled-components";

export const HomeWrapper = styled.div`
  width: 100%;
  min-height: 100vh;
  display: flex;
  background-color: black;

  @media screen and (max-width: 768px) {
    min-height: unset;
  }
`;

export const Feed = styled.div`
  width: 100%;
  min-height: 100vh;
  flex: 6;
  @media screen and (max-width: 768px) {
    min-height: unset;
  }
`;

export const SuggestionWrapper = styled.div`
  width: 100%;
  height: 100vh;
  padding-top: 2rem;
  flex: 3;
  border-left: 1px solid rgb(255, 255, 255, 0.25);

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const Header = styled.div`
  width: 100%;
  height: 100px;
  border-bottom: 1px solid #2a2a2a;

  @media screen and (max-width: 600px) {
    background-color: white;
    height: 70px;
    .header-search {
      display: none;
    }
  }

  .header-search {
    input {
      border-bottom: none;
      color: #b3b3b3;
      opacity: 1;
    }
    padding: 2px 1rem;
    width: 400px;
    max-width: 90%;
    border: 1px solid #b3b3b3;
    border-radius: 50px;
  }
`;

export const FeedWrapper = styled.div<{ stretch?: boolean }>`
  /* max-height: ${(props) => (props.stretch ? `100vh` : `calc(100vh - 100px)`)}; */
  padding: 1rem;
  overflow: hidden;

  .feed-content{
    width: 800px;
    max-width: calc(100% - 10px);
    margin: auto;
  }

  &:hover{
    overflow: auto;
  }

  .feed-box{
    max-width: 700px;
    margin: auto;
  }

  @media screen and (max-width: 768px) {
    /* max-height: ${(props) => (props.stretch ? `calc(100vh - 50px)` : `calc(100vh - 50px)`)}; */
    height: calc(100vh - 50px);
    overflow: auto;
  }
`;

export const SuggestedTitle = styled.h3`
  color: #ffffff;
  margin-top: 2rem;
  font-size: 1.4rem;
  font-weight: 100;
  padding-left: 2rem;
`

export const SuggestedContentWrapper = styled.div`
  max-height: calc(100vh - 100px);
  overflow: auto;
  padding: 1rem;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  height: calc(100% - 120px);
  overflow: hidden;
  &:hover{
    overflow: auto;
  }


  @media screen and (max-width: 768px) {
    max-height: calc(100vh - 120px);
  }
`;

export const SearchWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: .8rem;
  border-bottom: 1px solid rgb(255, 255, 255, 0.45);
  width: calc(100% - 2rem);
  margin-left: 1rem;
  padding-bottom: 0.5rem;

`

export const SearchInput = styled.input`
  background-color: transparent;
  color: #ffffff;

`

export const Logo = styled.img`
  width: 120px;
  max-width: 90%;
  @media screen and (min-width: 600px) {
    display: none;
  }
`;

export const CreatePostBtn = styled.button`
  background: linear-gradient(
    90deg,
    #259fd6 0%,
    #5d6abe 34.91%,
    #7554b4 62.9%,
    #7554b4 100%
  );
`;
