import styled from "styled-components";
import { Link } from 'react-router-dom';


export const Account = styled.div`
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  @media screen and (max-width: 768px){
      padding-bottom: 2rem;
    }

  h1{
    margin: 4rem 0;
    font-size: 2rem;
    font-weight: 500;

    @media screen and (max-width: 600px){
      font-size: 1.8rem;
    }
  }
`;

export const AccountLinkWrapper = styled.div`
  display: flex;
  width: calc(100% - 136px);
  justify-content: center;
  flex-direction: column;
  margin: auto;
  gap: 2rem;
  @media screen and (max-width: 768px){
    width: 100%;
  }
`;

export const AccountLinkBox = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);

  h3{
    font-size: 1.6rem;
    color: white;
    font-weight: 500;
    @media screen and (max-width: 600px){
        font-size: 1.3rem;
        font-weight: 400;
      }
  }

  svg{
    color: white;
    font-size: 1.7rem;
  }
`;