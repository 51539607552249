import styled from "styled-components";
import { Link } from "react-router-dom";

export const SidebardWrapper = styled.div`
  width: 250px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  height: 100vh;
  background: #000000;
  justify-content: space-between;
  color: white;
  overflow: auto;
  position: relative;
  /* box-shadow: 1px 1px 20px 0px #ffffff40;
  border-right: 1px solid rgba(255, 255, 255, 0.25); */

  @media screen and (max-width: 768px) {
    width: 100%;
    height: unset;
  }

  .contact-us {
    display: none;
  }

  hr {
    width: 90%;
    height: 1px;
    background-color: #d9d9d9;
    opacity: 50%;
    border: none;
    margin: 3rem auto;

    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const Logo = styled.img`
  width: 130px;
  object-fit: cover;
  margin: 3rem auto;

  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const ProfileImage = styled.img`
  width: 120px;
  aspect-ratio: 1/1;
  object-fit: cover;
  border-radius: 50%;
  margin-top: 1rem;
  background: white;
  @media screen and (max-width: 768px) {
    display: none;
  }
`;

export const SidebarItems = styled.div`
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 2rem;
  z-index: 500;
  position: relative;
  padding-top: 4rem;
  width: 80%;
  justify-content: space-between;
  @media screen and (max-width: 768px) {
    .create-post-icon {
      position: relative;
      bottom: 1.5rem !important;
      padding: 0;
    }

    width: 100%;
    height: 50px;
    flex-direction: row;
    align-items: center;
    justify-content: space-around;
    margin-bottom: 0;
    background: linear-gradient(
      90deg,
      #f5c6ff 0%,
      #cdb6ff 49.41%,
      #8d8bff 100%
    );
    position: fixed;
    bottom: 0;
    left: 0;
    padding-top: unset;
    gap: unset;
  }
`;

export const SidebarItem = styled(Link)<{ active: string }>`
  display: flex;
  align-items: center;
  justify-content: start;
  position: relative;
  gap: 2rem;
  cursor: pointer;

  svg {
    position: relative;
    bottom: 2px;
    font-size: 2.5rem;
    @media screen and (max-width: 768px) {
      color: black;
      bottom: 0;
      font-size: 45px;
      padding: 7px;
      border-radius: 50%;
      overflow: visible;
      ${({ active }) =>
        active === "true"
          ? `
        background: linear-gradient(90deg, rgba(111, 177, 252, 0.43) 0%, rgba(157, 0, 212, 0.21) 100%);
        `
          : ""}

      &:hover {
        background: linear-gradient(
          90deg,
          rgba(111, 177, 252, 0.43) 0%,
          rgba(157, 0, 212, 0.21) 100%
        );
      }
    }
  }
  .item-title {
    font-size: 18px;
    position: relative;
    bottom: 1px;
    @media screen and (max-width: 768px) {
      display: none;
    }
  }
`;

export const UserMenuContainer = styled.div<{ $open: boolean }>`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% - 50px);
  display: flex;
  flex-direction: column;
  z-index: 500;
  pointer-events: none;

  @media screen and (min-width: 1200px) {
    z-index: 600;
    height: 100%;
  }

  ${({ $open }) =>
    $open === true
      ? `
    pointer-events: all;
    .menu-sheild{
      opacity: 1;
      pointer-events: all;
    }

    .user-menu{
      transform: translateY(0);
    }
  `
      : ``}
`;

export const MenuSheild = styled.div`
  background-color: rgba(0, 0, 0, 0.5);
  flex: 1;
  opacity: 0;
  pointer-events: none;
  transition: 0.3s;
`;

export const UserMenu = styled.div`
  background-color: black;
  transform: translateY(30rem);
  z-index: 500;
  display: flex;
  flex-direction: column;
  transition: 0.3s;
`;

export const UserMenuItem = styled.div`
  color: white;
  font-size: 1.1rem;
  z-index: 500;
  position: relative;
  border-bottom: 1px solid #ffffff6e;
  padding-left: 1.5rem;
  height: 60px;
  display: flex;
  align-items: center;

  &:last-child {
    border-bottom: none;
    padding-bottom: 0;
  }
`;
