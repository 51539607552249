import { Modal } from "antd";
import styled from "styled-components";

export const ModalContent = styled(Modal)`
  .ant-modal-content {
    padding: 0;
    height: 80vh;
    overflow: hidden;
    border: 2px solid white;
    border-radius: 1rem;
  }
`;

export const UserImg = styled.img`
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
`;

export const MainWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 80vh;
`;
export const CommentWrapper = styled.div`
  flex: 1;
  overflow: auto;
  padding: 1rem;
`;

export const CommentAction = styled.div`
  height: 60px;
  border-top: 1px solid #101010;
  background: black;
  position: relative;
  display: flex;
  align-items: center;
  gap: 1rem;
  padding: 0 1rem;

  .comment-emoji-picker {
    position: absolute;
    bottom: 60px;
    left: 1rem;
    user-select: none;
    opacity: 0;
    pointer-events: none;
    transition: 0.1s;
    &.show {
      opacity: 1;
      pointer-events: all;
    }
  }
`;

export const CommentForm = styled.form`
  flex: 1;
  display: flex;
  align-items: center;
`;

export const CommentInput = styled.input`
  height: 100%;
  flex: 1;
  background: transparent;
  color: white;
  font-size: 18px;
`;
export const PostCommentButton = styled.button`
  height: 100%;
  padding: 0 1rem;
  background-color: transparent;
  color: #1677ff;
  cursor: pointer;
`;

export const Comment = styled.div`
  margin-bottom: 2rem;
  &:last-child {
    margin-bottom: 0;
  }
`;

export const CommentFlex = styled.div`
  display: flex;
  gap: 1rem;
  align-items: flex-start;
`;

export const SingleComment = styled.div`
  display: flex;
  align-items: start;
  background-color: #1b1b1b;
  border-radius: 2rem;
  padding: 0.5rem;
  margin-bottom: 1rem;
`;

export const UserCommented = styled.div``;

export const CommentText = styled.div`
  flex: 1;
  font-size: 15px;
  padding-right: 1rem;
  color: white;
`;

export const CommentUserName = styled.p`
  color: white;
`;

export const UserImage = styled.img`
  width: 30px;
  height: 30px;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
`;

export const NewComment = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;

  img {
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    object-fit: cover;
  }
  .comment-input-box {
    height: 50px;
    border: 1px solid rgb(255, 255, 255, 0.7);
    padding: 0.8rem 2rem;
    border-radius: 0.8rem;
    display: flex;
    align-items: center;
    flex: 1;
    input {
      background-color: black;
      color: rgb(255, 255, 255, 0.8);
      width: 100%;
      margin-right: 1.5rem;

      &::placeholder {
        color: rgb(255, 255, 255, 0.7);
      }
    }

    button {
      background-color: black;
      color: rgba(255, 255, 255, 0.7);

      &:hover {
        color: #ffffff;
      }
    }
  }
`;

export const CommentLike = styled.div`
  color: white;
  display: flex;
  align-items: center;
  gap: 0.5rem;
`