import React, { useEffect, useState } from "react";
import * as S from "./styles";
import * as interFace from "../../Config/interface.config";
import { IoSearch } from "react-icons/io5";
import { LuUserCircle2 } from "react-icons/lu";
import AppLogo from "../../Assets/Images/logo.svg";
import { useLocation, useNavigate } from "react-router-dom";
import * as CM from "../../Config/common.config";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { OutLineButton } from "../../common-styles";
import CreatePostModal from "../../Modals/CreatePostModal";
import { HiOutlineHome } from "react-icons/hi2";
import { PiChatCircleDots } from "react-icons/pi";
import { FaRegCircleUser } from "react-icons/fa6";

import { BsBarChart } from "react-icons/bs";
import { ReactComponent as CreateIcon } from "./Create.svg";
import { getUserDetails } from "../../Redux/slices/userSlice";

const Sidebar: React.FC = () => {
  const { role, image } = useSelector((state: RootState) => state.user);
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [createPostModal, setCreatePostModal] = useState(false);
  const [items, setItems] = useState<interFace.SidebarItems[]>([
    {
      id: 1,
      icon: <HiOutlineHome />,
      title: "Home",
      link: "/home",
    },
    {
      id: 2,
      icon: <IoSearch />,
      title: "Explore",
      link: "/explore",
    },
    {
      id: 3,
      icon: <PiChatCircleDots className="flex-none" />,
      title: "Messages",
      link: "/messages",
    },
    {
      id: 4,
      icon: <CreateIcon className="flex-none create-post-icon" />,
      title: "Create",
      link: "/create",
      onClick: () => setCreatePostModal(true),
      role: "CREATOR",
    },
    {
      id: 5,
      icon: <BsBarChart className="flex-none" />,
      title: "Activity",
      link: "/activity",
      role: "CREATOR",
    },
    {
      id: 6,
      icon: <FaRegCircleUser strokeWidth={"1px"} className="flex-none" />,
      title: "Account",
      link: "/account",
    },
  ]);
  const [userMenu] = useState<interFace.UserMenu[]>([
    {
      title: "Contact Support",
      link: "",
    },
    {
      title: "Edit Profile",
      link: "/profile/edit",
    },
    {
      title: "Change Password",
      link: "",
    },
    {
      title: "Payment & Billing",
      link: "",
    },
    {
      title: "Subscriptions",
      link: "",
    },
    {
      title: "Sign Out",
      link: "/logout",
    },
  ]);

  const { pathname } = useLocation();

  const menuClick = (link: string = "") => {
    if (link === "/logout") {
      localStorage.clear();
      window.location.href = "";
      return;
    }
    navigate(link);
    setIsMenuOpen(false);
  };

  useEffect(() => {
    if (CM.isCreatorRole(role)) {
      const non_creators_ids = [2];
      setItems([...items.filter(({ id }) => !non_creators_ids.includes(id))]);
    }
  }, [role]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  return (
    <>
      <S.UserMenuContainer $open={isMenuOpen}>
        <S.MenuSheild
          onClick={() => setIsMenuOpen(false)}
          className="menu-sheild"
        ></S.MenuSheild>
        <S.UserMenu className="user-menu">
          {userMenu.map((user, i) => (
            <S.UserMenuItem
              onClick={() => menuClick(user.link)}
              key={`user-menu-${i}`}
              className="cursor-pointer"
            >
              {user.title}
            </S.UserMenuItem>
          ))}
        </S.UserMenu>
      </S.UserMenuContainer>
      <S.SidebardWrapper>
        <div>
          <S.Logo src={AppLogo} />
          <S.SidebarItems>
            {items.map((item) => {
              if (item?.role === "CREATOR" && !CM.isCreatorRole(role)) return;

              return (
                <S.SidebarItem
                  onClick={(e) => {
                    if (!item.onClick) return e;
                    e.preventDefault();
                    item.onClick();
                  }}
                  active={`${pathname === item.link}`}
                  to={item.link}
                  key={item.id}
                >
                  {item.icon}
                  <span className="item-title">{item.title}</span>
                </S.SidebarItem>
              );
            })}
          </S.SidebarItems>
        </div>
        <div className="mb-8 contact-us">
          <OutLineButton className="w-[80%] justify-center">
            Contact Us
          </OutLineButton>
        </div>
      </S.SidebardWrapper>

      {/* Create Post Modal */}
      <CreatePostModal
        onHide={() => setCreatePostModal(false)}
        show={createPostModal}
        key={"create-post-modal"}
      />
    </>
  );
};

export default Sidebar;
