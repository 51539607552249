import React, { useCallback, useEffect, useState } from "react";
import * as E from "./style";
import * as C from "../../common-styles";
import { TbSearch } from "react-icons/tb";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import {
  fetchExploreList,
  subscribeCreator,
} from "../../Redux/slices/exploreSlice";
import { RoleTypes } from "../../Constant/enums/roleTypes";
import { getUserDetails } from "../../Redux/slices/userSlice";
import Suggesstion from "../../Components/Suggesstion";
import { ExploreList } from "../../Config/interface.config";
import { isCreatorRole } from "../../Config/common.config";
import SubscriptionModal from "../../Modals/SubscriptionModal";

let timeout: any;
const Explore: React.FC = () => {
  const { loading, results } = useSelector((state: RootState) => state.explore);
  const user = useSelector((state: RootState) => state.user);
  const dispatch: AppDispatch = useDispatch();
  const [searchedValue, setSearchedValue] = React.useState("");
  const [open, setOpen] = useState<boolean>(false);
  const [selectedCreator, setSelectedCreator] = useState<ExploreList | null>(
    null
  );

  const fetchCreatorsList = useCallback(
    (fullname?: string) => {
      let requestPayload = {
        limit: 10,
        page: 0,
        isFilterBySubscriber: true,
        role: RoleTypes.CREATOR,
      } as {
        limit: number;
        page: number;
        isFilterBySubscriber?: boolean;
        role?: string;
        fullname?: string;
      };

      if (fullname) requestPayload.fullname = fullname;

      dispatch(fetchExploreList(requestPayload));
    },
    [dispatch]
  );

  const onSubscribe = async (creator: ExploreList) => {
    if (user?.id) {
      setSelectedCreator(creator);
      setOpen(true);
    } else {
      dispatch(getUserDetails());
    }
  };

  useEffect(() => {
    fetchCreatorsList();
  }, []);

  useEffect(() => {
    if (timeout) clearTimeout(timeout);
    timeout = setTimeout(() => {
      fetchCreatorsList(searchedValue);
    }, 500);
  }, [searchedValue]);

  return (
    <E.Explore>
      <E.Header>
        <C.IconInputWrapper className="input-wrapper">
          <C.CommonInput
            className="explore-input"
            placeholder="Search Creators"
            onChange={(event) => setSearchedValue(event.target.value)}
          />
          <TbSearch className="input-icon" size={18} />
        </C.IconInputWrapper>

        <h1 className="mt-10 mb-0 text-white font-bold text-3xl">
          Suggestions for you
        </h1>
      </E.Header>
      <E.ProfileWrapper>
        {loading && <p className="text-white mt-5 text-center">loading...</p>}

        {!loading && results?.length === 0 && (
          <p className="text-white mt-5 text-center">No data to explore</p>
        )}

        {!loading &&
          results?.length > 0 &&
          results?.map((user, index) => (
            <div className="mb-10" key={`exp-sgst-key-${index}`}>
              <Suggesstion creator={user} subscribeCreator={onSubscribe} />
            </div>
          ))}
      </E.ProfileWrapper>

      {/* Buy subcription Modal */}
      {!isCreatorRole(user.role) && (
        <SubscriptionModal
          selectedCreator={selectedCreator}
          onHide={() => setOpen(false)}
          show={open}
        />
      )}
    </E.Explore>
  );
};

export default Explore;
