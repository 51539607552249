import React, { useState } from "react";
import * as L from "./style";
import { PiStarOfDavid } from "react-icons/pi";
import LogoLight from "../../Assets/Images/FanxoLight.svg";
import Card from "./Card";

import girlImageOne from "../../Assets/Images/Home/girl-img-one.jpg";
import girlImageTwo from "../../Assets/Images/Home/girl-img-two.jpg";
import girlImageThree from "../../Assets/Images/Home/girl-img-three.jpg";
import girlImageFour from "../../Assets/Images/Home/girl-img-four.jpg";
import girlImageFive from "../../Assets/Images/Home/girl-img-five.jpg";
import girlImageSix from "../../Assets/Images/Home/girlImageSix.png";

import iPhone11 from "../../Assets/Images/iphone11.png";
import * as interFace from "../../Config/interface.config";
import Slider from "react-slick";
import { useNavigate } from "react-router-dom";
const Landing: React.FC = () => {
  const navigate = useNavigate();
  const [famousUsers] = useState<interFace.FamousUsers[]>([
    {
      id: 1,
      name: "ANNA SHUMATE",
      followers: "21.4M",
      imageURL: girlImageOne,
    },
    {
      id: 2,
      name: "ANNA SHUMATE",
      followers: "21.4M",
      imageURL: girlImageTwo,
    },
    {
      id: 3,
      name: "ANNA SHUMATE",
      followers: "21.4M",
      imageURL: girlImageThree,
    },
    {
      id: 4,
      name: "ANNA SHUMATE",
      followers: "21.4M",
      imageURL: girlImageFour,
    },
    {
      id: 5,
      name: "ANNA SHUMATE",
      followers: "21.4M",
      imageURL: girlImageFive,
    },
  ]);

  const items = [
    {
      key: "1",
      label: "What is Fanxo? How does it work?",
      children: (
        <p>
          Fanxo helps creators monetize by turning their free fan following into
          premium fans. Creators set up account, add their fanxo link to their
          social media link-in-bio (like Instagram or YouTube). Fans sign up as
          premium members and get access to exclusive content
        </p>
      ),
    },
    {
      key: "2",
      label: " I am a creator. How do I set up my account?",
      children: (
        <p>
          Creators fill up the creator application form, provide their social
          media links, our team will review and accept them into the platform.
          For any assistance, you can contact us at business@fanxo.club.
        </p>
      ),
    },
    {
      key: "3",
      label: "Is there a limit to what I can earn?",
      children: (
        <p>
          No, there’s no limit! Your earning potential completely depends on you
          and your following
        </p>
      ),
    },
  ];

  const sliderSettings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  return (
    <L.Landing>
      <L.Header>
        <PiStarOfDavid color="#ffffff" size={60} />
        <L.Logo src={LogoLight} />
        <div className="flex items-center gap-4">
          <L.Login className="font-noto" to={"/login"}>
            Log in
          </L.Login>
          <L.SignUp className="font-noto" to={"/signup"}>
            Get Started
          </L.SignUp>
        </div>
      </L.Header>

      <L.SectionOne>
        <h1>#1st Time Ever In India!</h1>
        <h3 className="font-rifton-norm">
          EARN AS A CREATOR & <br /> CONNECT WITH YOUR TRUE FANS
        </h3>
        <div>
          <p>Monetize your community, post exclusive behind-the-</p>
          <p> scenes content,</p>
          <p>offer memberships and 10x your earnings only on fanxo.</p>
        </div>

        <L.CreatorBtn
          onClick={() => navigate("/signup/creator")}
          className="font-rifton-norm"
        >
          sign up as a creator
        </L.CreatorBtn>
      </L.SectionOne>

      <L.SectionTwo>
        <L.SubHeading className="font-rifton-norm">
          PREMIUM CONTENT FOR <br />
          PREMIUM FANS
        </L.SubHeading>
        <L.CardWrapper>
          <div className="card-container">
            {famousUsers.map((user) => (
              <Card {...user} key={`card-${user.id}`} />
            ))}
          </div>

          <div className="slider-container">
            <Slider {...sliderSettings}>
              {famousUsers.map((user, index) => (
                <L.CarouselWrapper key={`home-card-${index}`}>
                  <L.CarouselImg
                    src={user.imageURL}
                    alt="user"
                    crossOrigin="anonymous"
                  />
                </L.CarouselWrapper>
              ))}
            </Slider>
          </div>
        </L.CardWrapper>
      </L.SectionTwo>

      <L.SectionThree>
        <L.SubHeading className="font-rifton-norm">
          Earnings as you have <br />
          never seen
        </L.SubHeading>
        <L.SecThreeImg src={girlImageSix} alt="user" crossOrigin="anonymous" />
      </L.SectionThree>

      <L.SectionFour>
        <L.SubHeading className="font-rifton-norm">
          TRACK YOUR STATS
        </L.SubHeading>
        <L.SectionFlex>
          <L.PhoneImg src={iPhone11} />
          <L.StateBoxContainer>
            <L.StateBoxWrapper>
              <L.StateBox>
                <h5>SUBSCRIBERS</h5>
              </L.StateBox>
              <L.StateBox>
                <h5>PAID DM’S</h5>
              </L.StateBox>
            </L.StateBoxWrapper>

            <L.StateBoxWrapper>
              <L.StateBox>
                <h5>TIPS</h5>
              </L.StateBox>
              <L.StateBox>
                <h5>EARNINGS</h5>
              </L.StateBox>
            </L.StateBoxWrapper>
          </L.StateBoxContainer>
        </L.SectionFlex>
      </L.SectionFour>

      <L.FaqWrapper>
        <L.SubHeading className="font-rifton-norm">
          FREQUENTLY ASKED <br /> QUESTIONS
        </L.SubHeading>
        <L.CollaspeWrapper>
          <L.Collapse expandIconPosition="right" accordion items={items} />
        </L.CollaspeWrapper>
      </L.FaqWrapper>

      <L.Footer>
        <L.FooterItem to="/about-us" className="font-rifton-norm">
          About Us
        </L.FooterItem>
        <L.FooterItem to="/terms-and-conditions" className="font-rifton-norm">
          Terms and conditions
        </L.FooterItem>
        <L.FooterItem to="/privacy-policy" className="font-rifton-norm">
          Privacy policy
        </L.FooterItem>
        <L.FooterItem to="/faqs" className="font-rifton-norm">
          Faq
        </L.FooterItem>
      </L.Footer>
    </L.Landing>
  );
};

export default Landing;
