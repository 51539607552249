import EmojiPicker from 'emoji-picker-react';
import { BsEmojiSmile } from "react-icons/bs";
import * as C from './styled';
import React, { Dispatch, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { AppDispatch, RootState } from '../../Redux/store';
import { createNewComment } from '../../Redux/slices/commentSlice';
import toast from 'react-hot-toast';
import CommentSection from './Comment';
import { VscSend } from "react-icons/vsc";


interface Props {
  open: boolean,
  setOpen: Dispatch<React.SetStateAction<boolean>>
}

const Comments = ({
  open, setOpen
}: Props) => {
  const dispatch: AppDispatch = useDispatch()
  const [comment, setComment] = useState<string>('');
  const [show, setShow] = useState<boolean>(false);
  const { id: user_id } = useSelector((state: RootState) => state.user)
  const { comments, loading, post_id } = useSelector((state: RootState) => state.comment)

  useEffect(() => {
    const handleDocumentClick = (event: any) => {
      if (!event.target.closest(`.emoji-wrapper`)) {
        setShow(false);
      }
    };
    document.addEventListener('click', handleDocumentClick);
    return () => {
      document.removeEventListener('click', handleDocumentClick);
    };
  }, []);

  const newCommentHandler = (e: any) => {
    e.preventDefault();
    if(loading) return

    if (!user_id || !post_id) return toast.error('Please login and try again.');
    dispatch(createNewComment({
      text: comment,
      post: post_id,
      commentBy: user_id,
      likeCount: [],
      tips: []
    }))
    setComment('');
  }

  return (
    <C.ModalContent destroyOnClose closeIcon={false} open={open} width={900} onCancel={() => setOpen(false)} footer={false} className='!p-0'>
      <C.MainWrapper>
        <C.CommentWrapper id='main-comment-wrapper' className="bg-[#000000]">
          {comments.map((comment) => (
            <CommentSection comment={comment} key={`${comment._id}`} postId={post_id} />
          ))}
        </C.CommentWrapper>
        <C.CommentAction>
          <div className='emoji-wrapper'>
            {
              show &&
              <EmojiPicker
                onEmojiClick={(resp) => setComment(comment + resp.emoji)}
                className={`comment-emoji-picker ${show ? 'show' : ''}`} />
            }
            <BsEmojiSmile className='cursor-pointer'
              onClick={() => setShow(!show)}
              fill='#9ca3af' size={25} />
          </div>
          <C.CommentForm onSubmit={newCommentHandler}>
            <C.CommentInput value={comment} onChange={(e: { target: { value: React.SetStateAction<string>; }; }) => setComment(e.target.value)} placeholder='Add a comment...' type='text' />
            <C.PostCommentButton
              type='submit'
              onClick={newCommentHandler}
              className={comment.length ? '' : 'opacity-50'}
              disabled={!comment.length}>
                <VscSend fill='white' size={20}/>
              </C.PostCommentButton>
          </C.CommentForm>

        </C.CommentAction>
      </C.MainWrapper>
    </C.ModalContent>
  )
}

export default Comments
