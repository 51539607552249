import styled from "styled-components";

export const DragAndDrop = styled.div`

:where(.css-dev-only-do-not-override-1xg9z9n).ant-modal .ant-modal-content{
  background-color: #262626;
}
`;

export const Header = styled.div`
  h1{
    position: relative;
    text-align: center;
    color: white;
    font-weight: 500;
    font-size: 1.2rem;
    padding: 8px 5px;
  }
`

export const PostFooter = styled.div`
  text-align: center;
  button{
    background-color: #393939;
    color: white;
    padding: 0.3rem 1.5rem;
    border-radius: 4px;
    font-size: 14px;
    &:hover{
      color: white;
      background-color: #767676;
    }
  }
`

export const PostForm = styled.div``;

export const ShareButton = styled.button`
  background-color: transparent;
  cursor: pointer;
  color: #0888D4;
`

export const PrimaryButton = styled.button`
  padding: .6rem 2rem;
  border-radius: 4px;
  min-width: 300px;
  background-color: #0095F6;
  color: #ffffff;
  cursor: pointer;
  display: block;
  margin: auto;
`

export const UserInfoWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 1rem;
`

export const UserImg = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  object-fit: cover;
  border: 1px solid white;
`

export const TextAreaCount = styled.div`
  color: #ffffff;
  font-size: 14px;
`