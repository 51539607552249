import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import * as M from "./styled";
import { useDispatch, useSelector } from "react-redux";
import { createNewPost, updatePost } from "../Redux/slices/postSlice";
import { AppDispatch, RootState } from "../Redux/store";
import * as Yup from "yup";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import DefaultUserImg from "../Assets/Images/user-profile.png";
import StepOne from "../Components/Post/CreatePost/StepOne";
import { FaArrowLeft } from "react-icons/fa6";
import { MdVerified } from "react-icons/md";
import { CommonButton } from "../common-styles";
import {
  EditPostPayload,
  ExploreList,
  FeedPosts,
  PaymentSuccessResponse,
  UserDetails,
} from "../Config/interface.config";
import { IoIosCheckmarkCircleOutline } from "react-icons/io";
import {
  DEFAULT_PRICING,
  FANXOBILLPHONENUMBER,
  isCreatorRole,
  loadScript,
} from "../Config/common.config";
import APIRequest from "../API";
import * as APIPath from "../API/path";
import { OrderResponse } from "../Pages/Chat/chat.interface";
import {
  fetchExploreList,
  subscribeCreator,
} from "../Redux/slices/exploreSlice";
import { RoleTypes } from "../Constant/enums/roleTypes";
import { UserPublicProfile } from "../Pages/Profile/ViewProfile/interface";
import { Link } from "react-router-dom";

interface Props {
  onHide: () => void;
  show: boolean;
  selectedCreator?: ExploreList | null | UserPublicProfile;
}

const SubscriptionModal: React.FC<Props> = ({
  onHide,
  show,
  selectedCreator,
}) => {
  const { id, fullname, image, username, membership, memberDetails } =
    selectedCreator ?? {};
  const user = useSelector((state: RootState) => state.user);
  const [selectedPlan, setSelectedPlan] = useState(1);
  const dispatch: AppDispatch = useDispatch();

  const getPrice = (num: number) => {
    const price: number = parseInt(
      membership?.monthlyPrice
        ? membership?.monthlyPrice
        : memberDetails?.monthlyPrice
        ? memberDetails?.monthlyPrice
        : DEFAULT_PRICING.toString()
    );

    if (num === 1) return price;
    if (num === 3) return price * 3;
    return 0;
  };

  const selectedClassName =
    "bg-gradient-to-b from-[#146BC3] via-[#B77BC6] to-[#F48E1D]";

  const subscripCreatorOnPaymentSuccess = async (
    response: PaymentSuccessResponse
  ) => {
    const payload = {
      creatorId: id,
      orderId: response.razorpay_order_id,
    };

    const { type } = await dispatch(subscribeCreator(payload));
    if (type === "subscribe/rejected") {
      return toast.error("Unexpected error occured, please try again later");
    }

    toast.success(
      `Successfully subscribed to ${selectedCreator?.fullname} account`
    );

    const listPayload = {
      isFilterBySubscriber: true,
      page: 1,
      limit: 10000,
      role: RoleTypes.CREATOR,
    };
    dispatch(fetchExploreList(listPayload));
  };

  const initiatePayment = async ({ data }: OrderResponse) => {
    onHide();
    const res = await loadScript(
      "https://checkout.razorpay.com/v1/checkout.js"
    );

    if (!res) {
      alert("Razorpay SDK failed to load. Are you online?");
      return;
    }

    const paymentObject = new (window as any).Razorpay({
      key: process.env.REACT_APP_RAZORPAY_KEY_ID, // Enter the Key ID generated from the Dashboard
      amount: data.amount.toString(),
      currency: "INR",
      name: "Razorpay Integration",
      description: "Test Transaction",
      order_id: data.order_id,
      handler: subscripCreatorOnPaymentSuccess,
      prefill: {
        name: "@Fanxo",
        email: "fanxobusiness@gmail.com",
        contact: FANXOBILLPHONENUMBER,
      },
      // notes: {
      //   address: "Ahmedabad, Gujarat",
      // },
      theme: {
        color: "#001676",
      },
    });

    paymentObject.open();
  };

  const createSubscrptionOrder = async () => {
    try {
      toast.loading("Processing order");
      const { data, error } = await APIRequest(APIPath.createOrder, {
        creatorId: id,
        orderType: "subscription",
        duration: selectedPlan,
      });
      toast.remove();

      if (error) {
        return toast.error(error.message);
      }

      toast.success(data.message);
      initiatePayment(data);
    } catch (error) {
      console.log("error: ", error);
    }
  };

  return (
    <M.DragAndDrop>
      <Modal
        footer={false}
        className="create-post-modal"
        closeIcon={false}
        width={360}
        open={show}
        onCancel={onHide}
        destroyOnClose
      >
        <div className="w-full rounded-[15px] bg-gradient-to-b from-[#146BC3] via-[#B77BC6] to-[#F48E1D] p-0.5 overflow-hidden">
          <div className="flex h-full w-full items-center justify-center bg-black rounded-[15px]  overflow-hidden p-5">
            <div className="flex-1">
              <M.Header>
                <div className="mt-6">
                  <M.UserInfoWrapper>
                    <M.UserImg
                      crossOrigin="anonymous"
                      src={image ? image?.fileURL : DefaultUserImg}
                    />
                    <div>
                      <div className="flex items-center gap-2">
                        <p className="text-white capitalize text-xl mb-0">
                          {fullname}
                        </p>
                        <MdVerified className="text-white text-lg" />
                      </div>
                      <p className="text-white/60">@{username}</p>
                    </div>
                  </M.UserInfoWrapper>
                </div>
              </M.Header>

              <div className="mt-5">
                <p className="text-[15px] text-center font-medium mt-2 text-white/90">
                  Join <span className="capitalize">{fullname}'s</span> premiun
                  membership
                </p>

                <ul className="flex flex-col gap-4 mt-5">
                  <li className="flex items-center gap-3">
                    <IoIosCheckmarkCircleOutline
                      className="flex-none"
                      fill="#ffffffb3"
                      size={30}
                    />
                    <span className="text-white/70 text-[1rem]">
                      Behind the scene content.
                    </span>
                  </li>
                  <li className="flex items-center gap-3">
                    <IoIosCheckmarkCircleOutline
                      className="flex-none"
                      fill="#ffffffb3"
                      size={30}
                    />
                    <span className="text-white/70 text-[1rem]">
                      Surprise perks & messages from your favourite creator.
                    </span>
                  </li>
                </ul>
              </div>

              {/* Pricing Modal */}
              <div className="flex items-center justify-between gap-3 mt-8 flex-col sm:flex-row">
                {[1, 3].map((num) => (
                  <div
                    key={`subscription-plans-${num}`}
                    onClick={() => setSelectedPlan(num)}
                    className={`w-full cursor-pointer rounded-[15px] sm:max-w-[150px] p-0.5 overflow-hidden ${
                      num === selectedPlan ? selectedClassName : "bg-[#3d3d3d]"
                    }`}
                  >
                    <div className="border-white bg-black rounded-[15px] pt-4 overflow-hidden text-center sm:max-w-[150px]">
                      <div className="mb-3">
                        <h3 className="text-white text-[1rem]">
                          Just ₹{getPrice(num)} for
                        </h3>
                        <h3 className="text-white text-[1rem]">
                          {num} month{num > 1 ? "s" : ""}
                        </h3>
                        <p className="text-white/70 mb-0 text-[12px] mt-4">
                          Validity: Unlimited
                        </p>
                        <p className="text-white/70 mb-0 text-[12px]">
                          Expires: Monthly
                        </p>
                      </div>

                      <div onClick={() => setSelectedPlan(num)}>
                        {selectedPlan === num ? (
                          <div className="w-full py-1 font-light text-[14px] text-white bg-gradient-to-r from-[#146BC3] via-[#B77BC6] to-[#F48E1D]">
                            SELECTED
                          </div>
                        ) : (
                          <div className="w-full py-1 font-light text-[14px] text-white/70">
                            Click to Select
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
              <div className="mb-10">
                <CommonButton
                  variant="secondary"
                  className="w-full mt-8"
                  onClick={() => createSubscrptionOrder()}
                >
                  Continue
                </CommonButton>
                <p className="text-white/60 text-center mt-5 text-[12px] mx-4">
                  By clicking on "continue" you agree to Fanxo's Payment{" "}
                  <Link to="/terms-and-conditions">Terms, t&c</Link> and{" "}
                  <Link to="/privacy-policy">Privacy Policy.</Link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </Modal>
    </M.DragAndDrop>
  );
};

export default SubscriptionModal;
