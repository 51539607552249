import React from "react";
import * as C from "./style";
import userImg from "../../Assets/Images/user-profile-img.png";
import { MdVerified } from "react-icons/md";
import * as I from "../../Config/interface.config";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { selectSubscriber } from "../../Redux/slices/messageSlice";

interface Prop {
  subscriber: I.Subscriber;
  userId: string;
}

const ChatUser: React.FC<Prop> = ({ subscriber, userId }: Prop) => {
  const dispatch: AppDispatch = useDispatch();
  const { activeChat } = useSelector((state: RootState) => state.message);

  const remoteUser =
    subscriber.creator._id === userId
      ? subscriber.subscriber
      : subscriber.creator;

  return (
    <C.ChatUser
      className={`${activeChat === subscriber._id ? "bg-[#8B8B8B]" : ""}`}
      onClick={() =>
        dispatch(selectSubscriber({ subscriberId: subscriber._id }))
      }
    >
      {remoteUser && remoteUser?.image ? (
        <C.UserImage
          alt="user"
          crossOrigin="anonymous"
          src={remoteUser?.image.fileURL}
        />
      ) : (
        <C.UserImage src={userImg} />
      )}
      <C.UserDetail>
        <C.UserName>
          {remoteUser.fullname}
          <span>
            <MdVerified />
          </span>
        </C.UserName>
        <C.ChatContent>
          {/* <p className='last-message'>Lorem ipsum dolor sit amet consectetur adipisicing elit. Laudantium, magni.</p>
          <p className='last-seen'>12 mins ago</p> */}
        </C.ChatContent>
      </C.UserDetail>
    </C.ChatUser>
  );
};

export default ChatUser;
