import { useDispatch, useSelector } from "react-redux";
import * as P from "../style";
import * as C from "../../../common-styles";
import { AppDispatch, RootState } from "../../../Redux/store";
import { useEffect, useState } from "react";
import { Formik, Form } from "formik";
import { UserProfile } from "../../../Config/interface.config";
import { getUserDetails, updateProfile } from "../../../Redux/slices/userSlice";
import type { UploadProps } from "antd";
import { Input, message, Upload } from "antd";
import DefaultUserImg from "../../../Assets/Images/user-profile.png";
import * as API from "../../../API/path";
import { isCreatorRole } from "../../../Config/common.config";
import * as D from "../../../Components/UserDetails/style";
import { ReactComponent as PlusIcon } from "./plus-icon.svg";
import { ReactComponent as GalleryIcon } from "./gallery-icon.svg";
import { ReactComponent as LockIcon } from "./lock-icon.svg";
import WhiteTick from "../../../Assets/Icons/WhiteTick";

const EditProfile = () => {
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);
  const { socialLinks, fullname, totalPost = 0 } = user;

  const [profileData, setProfileData] = useState<UserProfile>({
    fullname: "",
    email: "",
    username: "",
    bio: "",
  });

  const { TextArea } = Input;

  useEffect(() => {
    if (!user.id || !user.email || !user.fullname) return;

    setProfileData({
      ...profileData,
      email: user.email,
      fullname: user.fullname,
      id: user.id,
      username: user.username ?? "",
      bio: user.bio,
    });
  }, [user]);

  useEffect(() => {
    if (!user.id || !user.email || !user.fullname) dispatch(getUserDetails());
  }, []);

  const updateProfileHandler = (updatedUser: UserProfile) => {
    if (!isCreatorRole(user.role)) delete updatedUser.username;
    if (!user.loading) dispatch(updateProfile(updatedUser));
  };

  const props: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_API_ENDPOINT}${API.fileUpload.url}`,
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false,
    method: "POST",
    onChange(info) {
      if (info.file.status !== "uploading") {
        message.loading("uploading profile picture...");
      }
      if (info.file.status === "done") {
        message.destroy();
        if (typeof info.file.response.name === "string")
          dispatch(updateProfile({ image: info.file.response._id }));
      } else if (info.file.status === "error") {
        message.error(`Error, try again later...`);
      }
    },
  };

  const propsBackgroundImage: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_API_ENDPOINT}${API.fileUpload.url}`,
    headers: {
      authorization: "authorization-text",
    },
    showUploadList: false,
    method: "POST",
    onChange(info) {
      if (info.file.status !== "uploading") {
        message.loading("uploading profile picture...");
      }
      if (info.file.status === "done") {
        message.destroy();
        if (typeof info.file.response.name === "string")
          dispatch(updateProfile({ backgroundImage: info.file.response._id }));
      } else if (info.file.status === "error") {
        message.error(`Error, try again later...`);
      }
    },
  };

  return (
    <P.ProfileEditWrapper>
      <D.ProfileHeaderContainer>
        <div className="section-one">
          {user?.backgroundImage?.fileURL && (
            <D.ProfileBackgroundImage
              crossOrigin="anonymous"
              src={user?.backgroundImage?.fileURL}
            />
          )}

          {isCreatorRole(user.role) && (
            <Upload
              showUploadList={false}
              className="profile-img-upload flex items-center justify-center"
              {...propsBackgroundImage}
            >
              <C.CommonButton
                variant="dark"
                className="flex items-center gap-3"
              >
                Add a cover
                <PlusIcon />
              </C.CommonButton>
            </Upload>
          )}
        </div>

        <div className="section-two">
          <div className="flex items-start justify-between mt-3">
            <div className="flex items-start gap-5">
              <div className="relative cursor-pointer profile-img-box bottom-[75px] rounded-full">
                <D.ProfileImage
                  crossOrigin="anonymous"
                  src={user?.image?.fileURL ?? DefaultUserImg}
                />
                <Upload
                  showUploadList={false}
                  className="profile-img-upload flex items-center justify-center"
                  {...props}
                >
                  <p className="text-white">Change</p>
                </Upload>
              </div>
              <div className="mb-1">
                <div className="flex gap-4 items-center">
                  <h1 className="text-white font-medium font-inter text-xl sm:text-3xl">
                    {fullname}
                  </h1>
                  {isCreatorRole(user.role) && <WhiteTick key={"white"} />}
                </div>
                {socialLinks?.username && (
                  <p className="text-white opacity-[67%] font-inter font-light">
                    @{socialLinks?.username}
                  </p>
                )}
              </div>
            </div>
            <div className="flex gap-5">
              {isCreatorRole(user.role) && (
                <div className="flex gap-2">
                  <GalleryIcon />
                  <span className="text-white count-span">{totalPost}</span>
                </div>
              )}

              {/* <div className="flex gap-2">
                <LockIcon />
                <span className="text-white count-span">0</span>
              </div> */}
            </div>
          </div>
        </div>
      </D.ProfileHeaderContainer>
      <P.FormWrapper>
        <Formik
          enableReinitialize
          initialValues={profileData}
          onSubmit={updateProfileHandler}
          //   validationSchema={validation.forgotPasswordValidation}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            dirty,
            submitForm,
          }) => (
            <Form>
              <div className="profile-edit-form">
                <div className="my-6">
                  <div className="mb-8">
                    <p className="text-white mb-0.5">Full Name </p>
                    <div className="flex-1">
                      <C.CommonInput
                        placeholder="Full Name"
                        name="fullname"
                        value={values.fullname}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>

                  <div className="mb-8">
                    <p className="text-white mb-0.5">Email Address </p>
                    <div className="flex-1">
                      <C.CommonInput
                        disabled
                        placeholder="Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                      />
                    </div>
                  </div>
                  {isCreatorRole(user.role) && (
                    <>
                      <div className="mb-8">
                        <p className="text-white mb-0.5">Description</p>
                        <TextArea
                          className="bg-black border-none border-b border-b-white"
                          rows={3}
                          placeholder="Write bio here..."
                          name="bio"
                          value={values.bio}
                          onChange={handleChange}
                          onBlur={handleBlur}
                        />
                      </div>

                      <div className="mb-8">
                        <p className="text-white mb-0.5">Username </p>
                        <div className="flex-1">
                          <C.CommonInput
                            placeholder="Username"
                            name="username"
                            value={values.username}
                            onChange={handleChange}
                            onBlur={handleBlur}
                          />
                        </div>
                      </div>
                    </>
                  )}

                  {dirty && (
                    <p onClick={submitForm} className="update-profile-btn">
                      Save Changes
                    </p>
                  )}

                  <p className="text-white mt-1">
                    {errors.email && touched.email && errors.email}
                  </p>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </P.FormWrapper>
    </P.ProfileEditWrapper>
  );
};

export default EditProfile;
