import * as interFace from "../../Config/interface.config";
import * as C from "./style";
import { FaInstagram } from "react-icons/fa6";

const Card: React.FC<interFace.FamousUsers> = ({
  followers,
  imageURL,
  name,
  id,
}: interFace.FamousUsers) => {
  return (
    <C.Card className={`famous-user-card card-${id}`}>
      <C.CardImg src={imageURL} alt="" crossOrigin="anonymous" />
      {/* <C.CardImg
        src={
          "https://images.pexels.com/photos/674010/pexels-photo-674010.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=1"
        }
      /> */}
      <C.CardContent>
        <h5>{name}</h5>
        <div className="flex items-center gap-2">
          <C.ViewProfileBtn>View Profile</C.ViewProfileBtn>
          <div className="flex items-end gap-2 followers-box">
            <FaInstagram color="#ffffff" size={22} />
            <span>{followers} Followers</span>
          </div>
        </div>
      </C.CardContent>
    </C.Card>
  );
};

export default Card;
