import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import * as R from "./styles";
import Home from "../Pages/Home";
import NotFound from "../Pages/NotFound";
import Sidebar from "../Components/Sidebar";
import AuthRoute from "./AuthRoute";
import Login from "../Pages/Login";
import SignUp from "../Pages/SignUp";
import Explore from "../Pages/Explore";
import Chat from "../Pages/Chat";
import Account from "../Pages/Account";
import Landing from "../Pages/Landing";
import ViewProfile from "../Pages/Profile/ViewProfile";
import CreatorDetails from "../Pages/CreatorDetails";
import Thankyou from "../Pages/Thankyou";
import ResetPassword from "../Pages/ResetPassword";
import ChangePassword from "../Pages/ChangePassword";
import ForgotPassword from "../Pages/ForgotPassword";
import EditProfile from "../Pages/Profile/EditProfile";
import Activity from "../Pages/Activity";
import MembershipPricing from "../Pages/MembershipPricing";
import { useSelector } from "react-redux";
import { RootState } from "../Redux/store";
import Membership from "../Pages/Membership";
import TermsAndCondition from "../Pages/TermsAndCondition";
import PrivacyPolicy from "../Pages/PrivacyPolicy";
import AboutUs from "../Pages/AboutUs";
import FAQs from "../Pages/Faqs";

interface RoutesInterface {
  element: React.FC;
  path: string;
  private?: boolean;
  sidebar?: boolean;
  header?: boolean;
}

const AppRoutes: React.FC = () => {
  const { viewProfilePage } = useSelector((state: RootState) => state.user);
  const [routes, setRoutes] = useState<RoutesInterface[]>([
    {
      element: Login,
      path: "/login",
      sidebar: false,
    },
    {
      element: Landing,
      path: "/",
      sidebar: false,
    },
    {
      element: SignUp,
      path: "/signup",
      sidebar: false,
    },
    {
      element: SignUp,
      path: "/signup/:role",
      sidebar: false,
    },
    {
      element: Home,
      path: "/home",
      sidebar: true,
      private: true,
    },
    {
      element: Explore,
      path: "/explore",
      sidebar: true,
      private: true,
    },
    {
      element: Chat,
      path: "/messages",
      sidebar: true,
      private: true,
    },
    {
      element: Account,
      path: "/account",
      sidebar: true,
      private: true,
    },
    {
      element: ViewProfile,
      path: "/profile/:username",
      sidebar: false,
      private: false,
    },
    {
      element: CreatorDetails,
      path: "/creator-details",
      sidebar: false,
      private: false,
    },
    {
      element: Thankyou,
      path: "/thankyou-for-applying",
      sidebar: false,
      private: false,
    },
    {
      element: ResetPassword,
      path: "/password/reset/:token",
      sidebar: false,
      private: false,
    },
    {
      element: ForgotPassword,
      path: "/password/forgot",
      sidebar: false,
      private: false,
    },
    {
      element: ChangePassword,
      path: "/password/change",
      sidebar: true,
      private: true,
    },
    {
      element: EditProfile,
      path: "/profile/edit",
      sidebar: true,
      private: true,
    },
    {
      element: Activity,
      path: "/activity",
      sidebar: true,
      private: true,
    },
    {
      element: MembershipPricing,
      path: "/membership/pricing",
      sidebar: true,
      private: true,
    },
    {
      element: Membership,
      path: "/user-membership",
      sidebar: true,
      private: true,
    },
    {
      element: TermsAndCondition,
      path: "/terms-and-conditions",
      sidebar: false,
      private: false,
    },
    {
      element: PrivacyPolicy,
      path: "/privacy-policy",
      sidebar: false,
      private: false,
    },
    {
      element: AboutUs,
      path: "/about-us",
      sidebar: false,
      private: false,
    },
    {
      element: FAQs,
      path: "/faqs",
      sidebar: false,
      private: false,
    },
    {
      element: NotFound,
      path: "*",
    },
  ]);

  useEffect(() => {
    if (viewProfilePage) {
      const allRoutes = [...routes];
      const index = routes.findIndex((rt) => rt.path === "/profile/:username");
      if (index !== -1) {
        allRoutes[index].sidebar = true;
        setRoutes(allRoutes);
      }
    }
  }, [viewProfilePage]);

  const componentRenderHandler = ({
    element: Element,
    sidebar,
    private: checkAuth,
  }: RoutesInterface) => {
    const PathElement = sidebar ? (
      <R.RoutesWrapper>
        {sidebar && <Sidebar />}
        <R.Content>
          <Element />
        </R.Content>
      </R.RoutesWrapper>
    ) : (
      <Element />
    );

    if (checkAuth) return <AuthRoute element={PathElement} />;
    return PathElement;
  };

  return (
    <Router>
      <Routes>
        {routes.map((route) => (
          <Route
            key={`key-${route.path}`}
            element={<>{componentRenderHandler(route)}</>}
            path={route.path}
          />
        ))}
      </Routes>
    </Router>
  );
};

export default AppRoutes;
