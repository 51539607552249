import styled from "styled-components";

export const LoginWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #1d1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 2rem 0;

  @media (max-width: 500px) {
    background: #000;
    padding: 0;
  }
`;

export const LoginContainer = styled.div`
  width: 600px;
  max-width: 95%;
  border-radius: 0.7rem;
  margin: auto;
  background-color: #000;
  padding: 2rem 0;
  /* height: 100%; */
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;

  @media (max-width: 500px) {
    width: 90%;
  }
`;

export const ColoredLogo = styled.img`
  width: 150px;
  max-width: 90%;
  margin: auto;
  display: block;
  margin-top: 2rem;
`;

export const LoginContent = styled.div`
  width: 70%;
  margin: 3rem auto;

  @media (max-width: 500px) {
    width: 100%;
  }
  h1 {
    color: white;
    font-size: 2.5rem;
    font-weight: 400;
    margin-left: 0.5rem;
  }

  .login-form {
    width: 100%;
    margin-top: 1.5rem;
    display: flex;
    flex-direction: column;
    gap: 2rem;

    @media (max-width: 500px) {
      width: 100%;
    }
  }

  .or-title {
    color: #4d4d4d;
    position: relative;
    text-align: center;
    span {
      background-color: #000000;
      position: relative;
      padding: 0 1rem;
      font-family: "Noto Sans", sans-serif;
    }
    &:before {
      content: "";
      width: 100%;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      height: 1px;
      background: #4d4d4d;
    }
  }
`;

export const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 20px;

  @media (max-width: 500px) {
    width: 100%;
  }

  form {
    width: 100%;
  }
`;
