import styled from "styled-components";

export const CommonLoader = styled.div`
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1.5rem;
  padding: 2rem 0;

  .common-loading-icon {
    color: white;
    font-size: 2rem;
    animation: animate 2s infinite;
  }

  @keyframes animate {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(720deg);
    }
  }
`;
