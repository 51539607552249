import React from "react";
import * as S from "./style";
import DefaultUserImg from "../../Assets/Images/user-profile.png";
import { MdVerified } from "react-icons/md";
import { PiCrownSimpleLight } from "react-icons/pi";
import { ExploreList } from "../../Config/interface.config";
import { Capitalize } from "../../Utils";
import { DEFAULT_PRICING } from "../../Config/common.config";
import { useNavigate } from "react-router-dom";

interface Props {
  creator: ExploreList;
  subscribeCreator: (creator: ExploreList) => void;
}

const Suggesstion: React.FC<Props> = ({ creator, subscribeCreator }: Props) => {
  const navigate = useNavigate();

  const showProfile = (username: string) => {
    navigate(`/profile/${username}`);
  };
  return (
    <S.SuggestionBox className="text-white">
      <S.ProfileImage
        onClick={() => showProfile(creator.username)}
        src={creator?.image ? creator.image.fileURL : DefaultUserImg}
        alt="creator"
        crossOrigin="anonymous"
      />
      <S.SugestionDetails onClick={() => showProfile(creator.username)}>
        <S.Username>
          {Capitalize(creator?.fullname)}
          <MdVerified />
        </S.Username>
        <S.EmailAddress>@{creator?.username}</S.EmailAddress>
      </S.SugestionDetails>

      {/* For Web View */}
      <S.JoinBtn
        className="!hidden sm:!flex"
        onClick={() => subscribeCreator(creator)}
      >
        <PiCrownSimpleLight size={20} strokeWidth={"10px"} />
        Join For ₹{creator?.membership?.monthlyPrice ?? DEFAULT_PRICING}
      </S.JoinBtn>
      {/* For Mobile View */}
      <S.JoinBtn
        className="!flex sm:!hidden"
        onClick={() => subscribeCreator(creator)}
      >
        <PiCrownSimpleLight size={20} strokeWidth={"10px"} />
        Join
      </S.JoinBtn>
    </S.SuggestionBox>
  );
};

export default Suggesstion;
