import styled from "styled-components";

export const CommonInput = styled.input`
  width: 100%;
  height: 2rem;
  background: transparent;
  outline: none;
  border: none;
  border-bottom: 1px solid #8f8f8f;
  color: #ffffff;
  font-family: "Roboto", sans-serif;
  font-size: 16px;
`;

export const CommonButton = styled.button<{
  variant: "primary" | "secondary" | "dark" | "tertary" | "light";
}>`
  font-family: "Inter", sans-serif;
  padding: 0.8rem 2rem;
  text-align: center;
  border-radius: 50px;
  cursor: pointer;
  font-size: 16px;
  transition: 0.3s;
  // Primary Button
  ${({ variant }) => {
    if (variant === "primary")
      return `
        background-color: #313131;
        color: rgba(255, 255, 255, 0.7);

        &:hover {
          color: white;
          background-color: #1e1e1e;
        }
    `;

    if (variant === "secondary")
      return `
        background: linear-gradient(
        90.57deg,
        #628eff 9.91%,
        #8740cd 53.29%,
        #580475 91.56%
        );

        &:hover {
          color: #ffffff;
          background: linear-gradient(
            90.57deg,
            #580475 9.91%,
            #8740cd 53.29%,
            #628eff 91.56%
          );
        }
      `;

    if (variant === "dark")
      return `
      background-color: #000000;
      color: #ffffff;
      padding: .5rem 1rem!important;
      border-radius: 8px!important;
      font-size: 15px;
      svg{
        fill: #ffffff;
      }
    `;

    if (variant === "tertary")
      return `
      background: linear-gradient(90deg, #1069C0 0%, #B07AC6 51.5%, #F48E1C 100%);
      border-radius: 10px!important;
      font-size: 1rem!important;
      font-weight: 600;
      width: 100%;
      // max-width: 350px;
    `;

    if (variant === "light")
      return `
      background: white;
      width: 100%;
      color: black;
      padding: 8px;
      font-weight: 600;
      border-radius: 10px;
      font-size: 18px;
      border: 1px solid white;
      transition: all.2s;
      &:hover {
        background: transparent;
        color: white;
      }
    `;
  }}
`;

export const CommonIonButton = styled.button`
  background-color: transparent;
  /* border: 0.6px solid #fff; */
  width: 100%;
  padding: 0.5rem 0;
  color: white;
  text-align: center;
  border-radius: 2rem;
  cursor: pointer;
  transition: 0.3s;
  font-weight: 500;
  font-size: 16px;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  font-weight: 400;
  gap: 1rem;
  padding: 5px 0;
`;

export const IconInputWrapper = styled.div`
  position: relative;
  .input-icon {
    position: absolute;
    top: 50%;
    right: 0px;
    transform: translate(-50%, -50%);
    cursor: pointer;
    color: #767676;
  }
`;

export const ColoredBtn = styled.button`
  z-index: 5;
  border: none;
  /* background: linear-gradient(90deg, #C1EFFE 0%, #D5C2FD 51.5%, #E9B9FE 100%); */

  background: linear-gradient(
    90.08deg,
    #0d6ac3 1.6%,
    rgba(200, 122, 227, 0.99) 55.89%,
    rgba(253, 145, 1, 0.99) 102.66%
  );

  width: 15rem;
  padding: 0.6rem 3rem;
  border-radius: 50px;
  width: unset;
  box-shadow: 0px 0px 7px 2px #00000040;
`;

export const CommonTransparentButton = styled.button`
  background-color: transparent;
  border: none;
  width: 100%;
  cursor: pointer;
  transition: 0.3s;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 1rem;
  padding: 5px 0;
`;

export const OutLineButton = styled.button<{ isIcon?: boolean }>`
  background-color: black;
  color: white;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: 0.5rem;
  font-size: 14px;
  padding: 0.5rem 1rem;
  border-radius: 50px;
  border: 1px solid #fff;
  font-weight: 500;

  @media screen and (max-width: 600px) {
    span {
      display: none;
    }
    ${({ isIcon }) =>
      isIcon === true &&
      `
       border: none;
       padding: 0;
    `}
  }
`;
