import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import * as config from "../Config/common.config";
import { login } from "./path";

interface APIRequestParams {
  url: string;
  method: string;
}

const APIRequest = async (
  { url, method }: APIRequestParams,
  data: any = null,
  params: any = null
): Promise<any> => {
  const apiurl = `${process.env.REACT_APP_API_ENDPOINT}${url}`;
  try {
    let token: string = config.getSessionToken();

    let payload: AxiosRequestConfig = {
      url: apiurl,
      method,
      headers: {
        Authorization: "Bearer " + token,
      },
    };

    if (params) payload.params = params;
    if (data) payload.data = data;

    const response: AxiosResponse<any> = await axios(payload);
    return { data: response.data };
  } catch (error: any) {
    if (
      error.response &&
      error.response.data &&
      error.response.data.code === 401
    ) {
      config.logout();
    }

    return {
      error: error?.response?.data ?? { message: "Server is unreachable" },
    };
  }
};

export default APIRequest;
