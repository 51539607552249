import { AiFillMacCommand } from "react-icons/ai";
import { AiOutlineLoading3Quarters } from "react-icons/ai";

import * as S from "./style";

interface Prop {
  message: string;
}

const CommonLoader = ({ message }: Prop) => {
  return (
    <S.CommonLoader>
      <AiOutlineLoading3Quarters className="common-loading-icon" /> {message}...
    </S.CommonLoader>
  );
};

export default CommonLoader;
