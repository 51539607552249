import React from "react";
import * as P from "./style";
import PostHeader from "./PostHeader";
import PostSlider from "./PostSlider";
import PostFooter from "./PostFooter";
import { FeedPosts } from "../../Config/interface.config";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Capitalize } from "../../Utils";

interface Props {
  locked?: boolean;
  post: FeedPosts;
}

const Post: React.FC<Props> = ({ locked = false, post }: Props) => {
  const user = useSelector((state: RootState) => state.user);
  return (
    <P.Post $locked={locked}>
      <PostHeader post={post} />
      <P.Description>{Capitalize(post?.text)}</P.Description>
      <PostSlider post={post} locked={post?.isLocked} />
      {!locked && <PostFooter post={post} />}
      {/* {!locked && <PostFame post={post} />} */}
    </P.Post>
  );
};

export default Post;
