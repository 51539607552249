import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as InterFace from "../../Config/interface.config";
import APIRequest from "../../API";
import * as APIPATHS from '../../API/path';
import { scrollToBottom } from "../../Config/common.config";
import { updateFeedPostComment } from "./postSlice";

const initialState: InterFace.Comment = {
  loading: false,
  post_id: '',
  comments: []
}

export const createNewComment = createAsyncThunk<any, InterFace.NewComment>(
  'comment/create-new-comment',
  async (commentData, { rejectWithValue, dispatch }) => {
    try {
      const response: any = await APIRequest(APIPATHS.createNewComment, commentData);
      if (response.error) throw new Error(response.error.message)
      dispatch(updateFeedPostComment({ comment: response.data, post_id: commentData.post }))
      return response.data;
    } catch (error: any) {
      return rejectWithValue({ reason: error.message ?? error.response.data });
    }
  }
);

const commentSlice = createSlice({
  name: 'comment',
  initialState,
  reducers: {
    selectedPostForComment(state, { payload }: PayloadAction<InterFace.SelectPost>) {
      state.post_id = payload.post_id;
      state.comments = payload.comments;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(createNewComment.pending, (state) => {
      state.loading = true;
    })

    builder.addCase(createNewComment.fulfilled, (state, { payload }) => {
      state.loading = false;
      state.comments.push(payload)
      setTimeout(() => scrollToBottom('main-comment-wrapper'), 10)
    })

    builder.addCase(createNewComment.rejected, (state) => {
      state.loading = false;
    })
  },
});

export const { selectedPostForComment } = commentSlice.actions;
export default commentSlice.reducer;
