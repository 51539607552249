import styled from "styled-components";

export const SuggestionBox = styled.div`
 display: flex;
 align-items: center;
 gap: 1rem;
`;

export const SugestionDetails = styled.div`
  width: 100%;
  cursor: pointer;

  p {
    color: #ffffff;
  }
`;

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1/1;
  /* position: absolute;
  top: 65%;
  left: 18%; */
  z-index: 10;
  /* transform: translate(-50%, -50%); */
`;

export const JoinBtn = styled.button`
 cursor: pointer;
  flex: none;
  background: linear-gradient(90deg, #DB77E5 0%, #7A5BC9 48.5%, #4B4DBB 100%);
  color: white;
  padding: .8rem .9rem;
  border-radius: 50px;
  font-weight: 500;
  display: flex;
  align-items: center;
  gap: .5rem;
`

export const Username = styled.p`
  font-size: 16px;
  font-weight: 700;
  display: flex;
  align-items: center;
  gap: .5rem;
`

export const EmailAddress = styled.p`
  font-size: 13px;
  color: #ffffff;
  opacity: 0.7;
`