import styled from "styled-components";

export const FAQWrapper = styled.div`
  width: 100%;
  height: 100vh;
  background: #1d1c1c;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: auto;
  padding: 2rem 0;
  color: @media screen and (max-width: 600px) {
    background-color: #000000;
  }
`;

export const Container = styled.div`
  width: 600px;
  max-width: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  border-radius: 0.7rem;
  margin: auto;
  background-color: #000;
  padding: 2rem;
`;

export const Contain = styled.div`
  h1 {
    color: white;
    font-size: 1.8rem;
    font-weight: 400;
  }

  .text-white {
    color: white;
  }

  .text-center {
    text-align: center;
  }
`;
