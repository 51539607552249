import * as S from "./styles";

const FAQs = () => {
  return (
    <S.FAQWrapper>
      <S.Container>
        <S.Contain>
          <h1>FAQ’s</h1>
          <div className="text-white">Last Updated: September 1, 2024</div>
          <div className="text-white my-2 font-semibold">
            Welcome to Fanxo: Where Creators make money from Their Biggest Fans
          </div>
          <div className="text-white my-2 font-semibold">
            Frequently Asked Questions (FAQ)
          </div>
          <div className="my-5 border border-cyan-50 border-spacing-2" />
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              1. What is Fanxo? How does it work?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Fanxo helps creators monetize by turning their free fan following
              into premium fans. Creators set up account, add their fanxo link
              to their social media link-in-bio (like Instagram or YouTube).
              Fans sign up as premium members and get access to exclusive
              content
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              2. I am a creator. How do I set up my account?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Creators fill up the creator application form, provide their
              social media links, our team will review and accept them into the
              platform. For any assistance, you can contact us at
              business@fanxo.club.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              3. Is there a limit to what I can earn?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              No, there’s no limit! Your earning potential completely depends on
              you and your following.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              4. What kind of content can I share on Fanxo?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              You can share all kinds of content format under the content
              guidelines, including texts, status updates, exclusive photos,
              videos, updates, and behind-the-scenes moments. The goal is to
              give your most loyal fans, a sense of access to content they won’t
              find anywhere else.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              5. Is Fanxo free to use for creators?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Yes, there are no upfront fees. Fanxo takes a small percentage of
              what you earn through memberships and paid DMs.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              6. How do fans join my premium community?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Fans can easily join by clicking on your Fanxo link, which you can
              add to your bio on Instagram or YouTube. They’ll be guided through
              the signup process to become premium members.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              7. Can I control how much to charge for premium content?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Yes, creators have full control over their pricing. You can set
              membership fees that work best for you and your fans
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              8. How will I receive my earnings?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Fanxo handles payments securely. You’ll receive your earnings
              directly in your bank account, hasslefree.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              9. What makes Fanxo different from other platforms?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Fanxo is built specifically for Indian creators, offering a more
              personalized and premium experience for both creators and fans. It
              allows you to build deeper connections with your audience while
              earning from your content.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              10. How long does it take for my account to be approved?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Once you submit your creator application, Fanxo will review it
              within a few hours. You’ll be notified via email once your account
              is approved.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              11. Can I change my membership pricing later?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Yes, you can adjust your membership pricing at any time. Simply
              update your pricing settings, and the changes will apply moving
              forward.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              12. What happens if I need help with my account?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Fanxo offers support to creators. If you run into any issues or
              need help, you can reach out to our support team through the
              platform for quick assistance.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              13. Can I link my Fanxo profile to multiple social media accounts?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Yes, you can share your Fanxo link across multiple social media
              platforms like Instagram, YouTube, and more to drive fans from
              different channels to your premium community.
            </div>
          </div>
          <div className="mt-2">
            <div className="text-white my-2 font-semibold">
              14. Are there any rules about what content I can post on Fanxo?
            </div>
            <div className="text-white mt-2 font-thin pl-4">
              Yes, Fanxo has community guidelines to ensure a positive
              experience for both creators and fans. Make sure to follow these
              guidelines when sharing content on the platform.
            </div>
          </div>
        </S.Contain>
      </S.Container>
    </S.FAQWrapper>
  );
};

export default FAQs;
