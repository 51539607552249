import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import type { PayloadAction } from "@reduxjs/toolkit";
import * as interFace from "../../Config/interface.config";
import * as PATH from "../../API/path";
import APIRequest from "../../API";
import toast from "react-hot-toast";

const initialState: Partial<interFace.UserDetails> = {
  loading: false,
  totalPost: 0,
  viewProfilePage: false,
};

export const getUserDetails = createAsyncThunk("/user/details", async () => {
  try {
    const { data }: any = await APIRequest(PATH.userDetails);
    return data;
  } catch (error) {}
});

export const updateProfile = createAsyncThunk<any, interFace.UserProfile>(
  "/user/update-profile",
  async (user, { rejectWithValue }) => {
    try {
      delete user.email;
      delete user.id;

      toast.loading("updating profile...");

      const { data }: any = await APIRequest(PATH.updateUser, user);

      toast.remove();
      toast.success("profile updated successfully");
      return data;
    } catch (error: any) {
      return rejectWithValue({ reason: error.message ?? error.response.data });
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    updateUser(state, { payload }: PayloadAction<interFace.UserDetails>) {
      Object.assign(state, payload);
    },
    viewProfilePage(state, { payload }: PayloadAction<boolean>) {
      state.viewProfilePage = payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getUserDetails.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(getUserDetails.fulfilled, (state, { payload }) => {
      Object.assign(state, payload);
      state.loading = false;
    });

    builder.addCase(getUserDetails.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(updateProfile.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(updateProfile.fulfilled, (state, { payload }) => {
      Object.assign(state, payload);
      state.loading = false;
    });

    builder.addCase(updateProfile.rejected, (state) => {
      state.loading = false;
    });
  },
});

export const { updateUser, viewProfilePage } = userSlice.actions;
export default userSlice.reducer;
