import styled from "styled-components";

export const Activity = styled.div`
  width: 800px;
  max-width: 90%;
  margin: 0 auto;
  padding-bottom: 4rem;

  h1 {
    margin: 4rem 0;
    font-size: 2rem;
  }
`;
