import styled from "styled-components";

export const MemberShip = styled.div`
  color: white;
  margin-top: 5rem;
  max-width: 550px;
  width: 100%;
  margin: auto;
  padding: 2rem 0.5rem;
`;
