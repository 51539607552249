import { createAsyncThunk, createSlice, PayloadAction } from "@reduxjs/toolkit";
import * as I from "../../Config/interface.config";
import APIRequest from "../../API";
import * as APIPATH from "../../API/path"
import { messageScrollHandler } from "../../Utils";

const initialState: {
  messages: I.Message[];
  subscribers: I.Subscriber[];
  loading: boolean;
  activeChat: string;
  messageLoading: boolean;
  paymentModal: boolean;
} = {
  subscribers: [],
  messages: [],
  loading: false,
  activeChat: "",
  messageLoading: false,
  paymentModal: false
};

export const fetchSubscribers = createAsyncThunk<any, string>(
  "messages/fetch-subscribers",
  async (userId, { rejectWithValue }) => {
    try {
      const path = { ...APIPATH.get_suscribers };
      path.url = path.url.replace("{{userid}}", userId);
      const { data }: { data: I.Subscriber[] } = await APIRequest(path);
      return data;
    } catch (error: any) {
      return rejectWithValue({ reason: error.message ?? error.response.data });
    }
  }
);

export const fetchMessages = createAsyncThunk('messages/fetch-messages',
  async () => {
    try {
      const { data }: { data: I.MessageListResponse } = await APIRequest(APIPATH.getMessages);
      const { status, data: messages } = data;
      if (!status) return []
      return messages;
    } catch (error) {
      return [];
    }
  })

// export const sendMessage = createAsyncThunk('messages/send-message', async () => {
//   try {
//     const { data } = await APIRequest(APIPATH.newMessage,);
//     console.log('datadatadata: ', data)
//   } catch (error) {
//     return null;
//   }
// })

const messageSlice = createSlice({
  name: "messages",
  initialState,
  reducers: {
    selectSubscriber: (state, { payload }: PayloadAction<{ subscriberId: string }>) => {
      state.activeChat = payload.subscriberId
    },
    appendNewMessage: (state, { payload }: PayloadAction<{ newmessage: I.Message }>) => {
      state.messages.push(payload.newmessage)
      messageScrollHandler()
    },
    togglePaymentModal: (state) => {
      state.paymentModal = !state.paymentModal;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchSubscribers.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchSubscribers.fulfilled, (state, { payload }: PayloadAction<I.Subscriber[]>) => {
      state.loading = false;
      state.subscribers = payload;
    });

    builder.addCase(fetchSubscribers.rejected, (state) => {
      state.loading = false;
    });

    builder.addCase(fetchMessages.pending, (state) => {
      state.loading = true;
    });

    builder.addCase(fetchMessages.fulfilled, (state, { payload }: PayloadAction<I.Message[]>) => {
      state.loading = false;
      state.messages = payload;
    });

    builder.addCase(fetchMessages.rejected, (state) => {
      state.loading = false;
    });

  },
});

export const { selectSubscriber, appendNewMessage, togglePaymentModal } = messageSlice.actions

export default messageSlice.reducer;
