import React, { useEffect, useCallback, useState } from "react";
import * as H from "./style";
import Post from "../../Components/Post/Post";
import Suggesstion from "../../Components/Suggesstion";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { feedHandler } from "../../Redux/slices/postSlice";
import { isCreatorRole } from "../../Config/common.config";
import { getUserDetails } from "../../Redux/slices/userSlice";
import { IoSearchSharp } from "react-icons/io5";
import { RoleTypes } from "../../Constant/enums/roleTypes";
import {
  fetchExploreList,
  subscribeCreator,
} from "../../Redux/slices/exploreSlice";
import { useNavigate } from "react-router-dom";
import ProfileHeader from "../../Components/Profile/ProfileHeader";
import CommonLoader from "../../Components/CommonLoader";
import SubscriptionModal from "../../Modals/SubscriptionModal";
import { ExploreList } from "../../Config/interface.config";

const Home: React.FC = () => {
  const dispatch: AppDispatch = useDispatch();
  const { feed, loading } = useSelector((state: RootState) => state.posts);
  const user = useSelector((state: RootState) => state.user);
  const creators = useSelector((state: RootState) => state.explore);
  const [open, setOpen] = useState(false);
  const [selectedCreator, setSelectedCreator] = useState<ExploreList | null>(
    null
  );

  const { role, id: UserId } = useSelector((state: RootState) => state.user);

  const fetchCreatorsList = useCallback(() => {
    let requestPayload = {
      limit: 1000,
      page: 0,
      isFilterBySubscriber: true,
      role: RoleTypes.CREATOR,
    } as {
      limit: number;
      page: number;
      isFilterBySubscriber?: boolean;
      role?: string;
      fullname?: string;
    };
    dispatch(fetchExploreList(requestPayload));
  }, [dispatch]);

  const onSubscribe = async (creator: ExploreList) => {
    if (user?.id) {
      setSelectedCreator(creator);
      setOpen(true);
    } else {
      dispatch(getUserDetails());
    }
  };

  useEffect(() => {
    let requestPayload = { limit: 1000, page: 0 } as {
      limit: number;
      page: number;
      creator?: string;
    };
    if (role && isCreatorRole(role)) {
      requestPayload = { ...requestPayload, creator: user.id };
    }
    dispatch(feedHandler(requestPayload));
  }, [UserId, role]);

  useEffect(() => {
    dispatch(getUserDetails());
    fetchCreatorsList();
  }, []);

  if (loading) return <CommonLoader message="Preparing your feed" />;

  return (
    <H.HomeWrapper>
      <H.Feed className="feed">
        <H.FeedWrapper stretch={isCreatorRole(role)}>
          <div>
            {isCreatorRole(role) && <ProfileHeader user={user} />}

            <div className="feed-box">
              {feed?.results?.map((post, index) => (
                <Post key={`post-key-${index}`} post={post} />
              ))}
            </div>
          </div>
        </H.FeedWrapper>
      </H.Feed>

      {!isCreatorRole(role) && (
        <H.SuggestionWrapper>
          <H.SearchWrapper>
            <IoSearchSharp fill="#ffffff" size={25} strokeWidth={0.5} />
            <H.SearchInput placeholder="SEARCH" />
          </H.SearchWrapper>
          {creators.loading && (
            <p className="text-white mt-5 text-center">loading...</p>
          )}

          {!creators.loading && creators.results?.length === 0 && (
            <p className="text-white mt-5 text-center">No data to explore</p>
          )}
          <H.SuggestedTitle>Suggested For You</H.SuggestedTitle>
          <H.SuggestedContentWrapper>
            {creators.results.map((user, index) => (
              <Suggesstion
                key={`suggestion-key-${index}`}
                creator={user}
                subscribeCreator={onSubscribe}
              />
            ))}
          </H.SuggestedContentWrapper>
        </H.SuggestionWrapper>
      )}

      {/* Buy subcription Modal */}
      {!isCreatorRole(user.role) && (
        <SubscriptionModal
          selectedCreator={selectedCreator}
          onHide={() => setOpen(false)}
          show={open}
        />
      )}
    </H.HomeWrapper>
  );
};

export default Home;
