import { createSlice, createAsyncThunk, PayloadAction } from "@reduxjs/toolkit";
import APIRequest from "../../API";
import * as APIPATHS from "../../API/path";
import toast from "react-hot-toast";
import * as InterFace from "../../Config/interface.config";

const initialState: InterFace.ExploreInitialState = {
  results: [],
  page: 0,
  limit: 10,
  totalPages: 0,
  totalResults: 0,
  loading: false,
  isModalOpen: false,
};

export const fetchExploreList = createAsyncThunk<
  any,
  InterFace.GetCreatorListQueryParams
>("creatorlist", async (requestPayload, { rejectWithValue }) => {
  try {
    const response: any = await APIRequest(
      APIPATHS.exploreList,
      {},
      { ...requestPayload }
    );

    if (response.error) throw new Error(response.error.message);
    return response.data;
  } catch (error: any) {
    return rejectWithValue({ reason: error.message ?? error.response.data });
  }
});

export const fetchMembershipList = createAsyncThunk<
  any,
  InterFace.GetCreatorListQueryParams
>("creatorlist", async (requestPayload, { rejectWithValue }) => {
  try {
    const response: any = await APIRequest(
      APIPATHS.exploreList,
      {},
      { ...requestPayload }
    );

    if (response.error) throw new Error(response.error.message);
    return response.data;
  } catch (error: any) {
    return rejectWithValue({ reason: error.message ?? error.response.data });
  }
});

export const subscribeCreator = createAsyncThunk<
  any,
  InterFace.SubscribeCreator
>("subscribe", async (subscriberData, { rejectWithValue }) => {
  try {
    toast.loading("Requesting to subscribe...");
    const response: any = await APIRequest(
      APIPATHS.subscribeHandler,
      subscriberData
    );
    toast.remove();
    if (response.error) throw new Error(response.error.message);
    return response.data;
  } catch (error: any) {
    return rejectWithValue({ reason: error.message ?? error.response.data });
  }
});

const exploreSlice = createSlice({
  name: "explore",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchExploreList.pending, (state) => {
        state.loading = true;
      })
      .addCase(
        fetchExploreList.fulfilled,
        (state, action: PayloadAction<InterFace.ExploreListResposne>) => {
          Object.assign(state, action.payload);
          state.loading = false;
        }
      )
      .addCase(fetchExploreList.rejected, (state, action: any) => {
        state.loading = false;
        toast.error(action.payload.reason);
      });
    builder
      .addCase(subscribeCreator.pending, (state) => {
        state.loading = true;
      })
      .addCase(subscribeCreator.fulfilled, (state, _) => {
        state.loading = false;
      })
      .addCase(subscribeCreator.rejected, (state) => {
        state.loading = false;
      });
  },
});

export default exploreSlice.reducer;
