import styled from "styled-components";

export const SubscribedUser = styled.div`
  color: white;
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-width: 100%;
  .creator-details {
    display: flex;
    align-items: center;
    gap: 1rem;
    img {
      width: 50px;
      height: 50px;
      aspect-ratio: 1/1;
      border-radius: 50%;
      object-fit: cover;
      border: 1px solid #ddd;
    }

    h3 {
      font-size: 1rem;
      font-weight: 500;
      display: flex;
    }

    p {
      color: #fff;
      opacity: 0.7;
      font-size: 15px;
      margin-bottom: 0;
    }
  }

  .expire-details {
    display: flex;
    align-items: center;
    gap: 1rem;
    p {
      color: #fff;
      opacity: 0.7;
      margin-bottom: 0;
    }
  }
`;

export const ProfileImage = styled.img`
  width: 50px;
  height: 50px;
  cursor: pointer;
  border-radius: 50%;
  object-fit: cover;
  aspect-ratio: 1/1;
  /* position: absolute;
  top: 65%;
  left: 18%; */
  z-index: 10;
  /* transform: translate(-50%, -50%); */
`;