import * as S from "./styles";

const AboutUs = () => {
  return (
    <S.AboutUsWrapper>
      <S.Container>
        <S.Contain>
          <h1>About us</h1>
          <div className="text-white">Last Updated: September 1, 2024</div>
          <div className="text-white my-2 font-semibold">
            Welcome to Fanxo: Where Creators make money from Their Biggest Fans
          </div>
          <div className="text-white mt-2 font-thin">
            At Fanxo, we help creators turn their fan followings into premium
            communities. If you’ve built a free fan base on Instagram, YouTube,
            or any other social media, Fanxo lets you take things to the next
            level. With our link-in-bio tool, you can start offering exclusive
            content and paid DMs to your most loyal fans, bringing in extra cash
            while building a closer connection with your community.
          </div>
          <div className="text-white mt-2 font-thin">
            It’s easy for creators to control everything — from the type of
            content they share to the price fans pay. Fans get a VIP experience,
            and you get to grow a premium community that supports you.
          </div>
          <div className="my-5 border border-cyan-50 border-spacing-2" />
          <div className="text-white my-2 font-semibold">What We’re Fixing</div>
          <div className="text-white mt-2 font-thin">
            Social media is great for building a following, but it’s tough for
            creators to make real money just from free followers. Sponsorships
            and ads might bring in a bit, but they don’t let creators get closer
            to their true fans. Fans, on the other hand, want more than just
            regular posts — they want behind-thescenes content and personal
            interactions.
          </div>
          <div className="text-white mt-2 font-thin">
            That’s where fanxo steps in. We give creators the tools to make
            money from their biggest supporters by offering premium memberships
            and paid DMs. You set the price within our range, and fans who want
            more can pay for special content or a personal message. It’s a
            win-win: creators get paid for their hard work, and fans get to feel
            even closer to the people they admire.
          </div>
          <div className="text-white mt-2 font-thin">
            Fanxo makes it simple to earn without changing how you run your free
            accounts. You keep your regular content going, while your most loyal
            fans get extra perks through the premium options.
          </div>
          <div className="text-white my-2 font-semibold">
            Why Fanxo? The Best Tool for Creators
          </div>
          <div className="text-white mt-2 font-thin">
            Fanxo is built with creators in mind. We know you're already doing a
            lot to keep your audience engaged, and we want to make it easier for
            you to earn money while doing what you love. Unlike other platforms,
            Fanxo focuses on helping creators monetize their loyal fans without
            complicated processes or taking away from their free content.
          </div>
          <div className="text-white my-2 font-semibold">
            Here's why creators love Fanxo:
          </div>
          <div className="text-white mt-2 font-thin">
            Easy to Use: Our link-in-bio tool fits right into your existing
            social media profiles, like Instagram or YouTube, so you can start
            offering premium content with just a few clicks.
          </div>
          <div className="text-white mt-2 font-thin">
            Low Setup Times: Fill out the creator application, if we like your
            profile, we will approve it. You can setup your account within 5
            minutes.
          </div>
          <div className="text-white mt-2 font-thin">
            Control the Experience: You decide what premium content to offer and
            how much to charge.
          </div>
          <div className="text-white mt-2 font-thin">
            More Revenue, Less Hassle: Instead of relying on ads or waiting for
            sponsorships, Fanxo helps you directly earn from your biggest fans.
            You get paid for the content you already create, in a way that works
            for both you and your fans.
          </div>
          <div className="text-white mt-2 font-thin">
            With Fanxo, you're not just gaining a premium platform — you're
            creating a space where your fans feel even more connected to you
            while supporting your work.
          </div>
          <div className="text-white my-2 font-semibold">
            Fanxo Benefits: Why It's Great for Creators and Fans
          </div>
          <div className="text-white mt-2 font-thin">
            Fanxo isn't just another tool — it’s a game-changer for both
            creators and their fans. Here's how everyone benefits:
          </div>
          <div className="text-white my-2 font-semibold">For Creators:</div>
          <div className="text-white my-2 font-semibold">
            Earn More from Your Biggest Fans
          </div>
          <div className="text-white mt-2 font-thin">
            We get it, ads and sponsorships aren’t always reliable. Fanxo gives
            you a direct way to earn from the fans who already love your
            content. Whether it’s through premium memberships or paid DMs,
            you’re in control of your revenue stream.{" "}
          </div>
          <div className="text-white mt-2 font-thin">
            Build a Premium Community
          </div>
          <div className="text-white mt-2 font-thin">
            It’s not just about making money — it’s about creating a space where
            your most loyal fans feel connected to you. With Fanxo, you’re
            building a premium community of people who truly support your work
            and want to be part of your journey.
          </div>
          <div className="text-white mt-2 font-thin">Total Control</div>
          <div className="text-white mt-2 font-thin">
            You decide what content to offer and how much to charge. Want to
            share exclusive behind-thescenes content or offer paid DMs? It’s all
            up to you. Fanxo puts you in the driver’s seat, making it easy to
            manage everything from one place.
          </div>
          <div className="text-white mt-2 font-thin">No Extra Effort</div>
          <div className="text-white mt-2 font-thin">
            You’re already putting in work creating amazing content, so Fanxo
            fits right into what you’re doing. Just add your link-in-bio to your
            social profiles like Instagram or YouTube, and let your fans know
            they can get more. No need to overhaul your whole system — Fanxo
            works with what you’ve already built.
          </div>
          <div className="text-white mt-2 font-thin">For Fans:</div>
          <div className="text-white mt-2 font-thin">Exclusive Access</div>
          <div className="text-white mt-2 font-thin">
            Fans get behind-the-scenes content and access they won’t find
            anywhere else. From special photos to private videos, they feel like
            they’re getting VIP treatment from the creator they love.
          </div>
          <div className="text-white mt-2 font-thin">Closer Connection</div>
          <div className="text-white mt-2 font-thin">
            Fans don’t just want to see your public posts — they want a real
            connection. Fanxo lets them feel like part of your inner circle. And
            with features like paid DMs, they can even get personal messages
            from you, making the experience even more special.
          </div>
          <div className="text-white mt-2 font-thin">
            Support Their Favorite Creators
          </div>
          <div className="text-white mt-2 font-thin">
            Fans love to support the creators they admire. With Fanxo, they get
            to do that directly, knowing their money is going to the person they
            care about — you! It’s a win-win where fans feel valued, and you
            earn from the hard work you’re already putting in.
          </div>
          <div className="text-white mt-2 font-thin">
            Fanxo is all about making sure everyone wins. Creators get to
            monetize their most loyal fans, and fans get closer to the creators
            they love. It’s that simple.
          </div>
          <div className="text-white my-2 font-semibold">
            The Future of Fanxo and the Creator Economy
          </div>
          <div className="text-white mt-2 font-thin">
            At Fanxo, we’re not just focused on today — we’re building for the
            future of the creator economy, with a special focus on the
            Asia-Pacific region. Here’s what we’re aiming for:
          </div>
          <div className="text-white mt-2 font-thin">
            Expanding Within Asia-Pacific
          </div>
          <div className="text-white mt-2 font-thin">
            Our primary goal is to grow Fanxo’s presence across the Asia-Pacific
            region. We’re dedicated to helping creators in this vibrant and
            diverse area build and monetize their premium communities. By
            tailoring our services to meet the unique needs of creators and fans
            in Asia-Pacific, we aim to support the local creator economy and
            foster a strong, engaged community.
          </div>
          <div className="text-white mt-2 font-thin">
            Innovating for Creators
          </div>
          <div className="text-white mt-2 font-thin">
            We’re committed to continuously enhancing the Fanxo experience. This
            means developing new features that make it easier for creators to
            connect with their fans, offering more customization options, and
            staying ahead of industry trends. Our goal is to ensure that Fanxo
            remains the top choice for creators looking to grow their
            communities and maximize their revenue.
          </div>
          <div className="text-white mt-2 font-thin">
            Enhancing Fan Engagement
          </div>
          <div className="text-white mt-2 font-thin">
            We want to create even more ways for fans to interact with their
            favorite creators. Our vision includes adding features that deepen
            fan engagement and make the experience more personal and rewarding.
            We’re dedicated to ensuring that fans feel valued and connected
            through exclusive content and interactions.
          </div>
          <div className="text-white mt-2 font-thin">
            Building a Strong Regional Community
          </div>
          <div className="text-white mt-2 font-thin">
            By focusing on the Asia-Pacific market, we’re committed to building
            a dynamic and diverse community of creators and fans. We believe
            that by addressing the specific needs and preferences of this
            region, we can create a thriving ecosystem where creators can
            succeed and fans can enjoy a richer, more connected experience.
          </div>
          <div className="text-white mt-2 font-thin">
            At Fanxo, we’re excited about the future and committed to making a
            significant impact on the creator economy within Asia-Pacific. We’re
            dedicated to supporting creators and providing fans with exceptional
            experiences. Together, we’re shaping the future of how creators and
            fans connect in this vibrant region.
          </div>
          <div className="text-white my-2 font-semibold">
            Join the Fanxo Movement
          </div>
          <div className="text-white mt-2 font-thin">
            Ready to take your creator journey to the next level? Fanxo is here
            to help you turn your loyal fans into a thriving premium community.
            Whether you’re a creator looking to monetize your existing fan base
            or a fan eager to get closer to your favorite creators, Fanxo makes
            it easy and rewarding for everyone involved.
          </div>
          <div className="text-white mt-2 font-thin">For Creators:</div>
          <div className="text-white mt-2 font-thin">
            Get Started with Ease: Setting up your premium community is simple.
            Just add our link-in-bio to your social media profiles, choose what
            exclusive content to offer, and start earning from your most
            dedicated fans.
          </div>
          <div className="text-white mt-2 font-thin">
            Connect and Grow: Build a deeper connection with your fans and watch
            your premium community thrive. Fanxo provides the tools to enhance
            your engagement and grow your revenue in a way that fits seamlessly
            into your existing content strategy
          </div>
          <div className="text-white mt-2 font-thin">
            Support and Resources: Our team is here to support you every step of
            the way. From setting up your account to optimizing your content
            strategy, we’re dedicated to helping you succeed.
          </div>
          <div className="text-white mt-2 font-thin">For Fans:</div>
          <div className="text-white mt-2 font-thin">
            Experience Exclusive Content: Unlock behind-the-scenes access,
            special content, and personal interactions with your favorite
            creators. Fanxo gives you a VIP experience that makes you feel
            closer than ever.
          </div>
          <div className="text-white mt-2 font-thin">
            Support Creators Directly: Show your support by becoming a premium
            member. Your contributions help creators continue producing the
            content you love and enjoy unique perks in return.
          </div>
          <div className="text-white mt-2 font-thin">
            Join us in reshaping the creator economy. With Fanxo, you’re not
            just participating in a platform — you’re becoming part of a
            movement that values meaningful connections and rewards creativity.
          </div>
          <div className="text-white mt-2 font-thin">
            Ready to get started? Sign up today and see how Fanxo can transform
            the way you connect with your community, whether you’re a creator or
            a fan. Together, let’s build something amazing!
          </div>
        </S.Contain>
      </S.Container>
    </S.AboutUsWrapper>
  );
};

export default AboutUs;
