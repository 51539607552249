import * as S from "./styled";
import { MdVerified } from "react-icons/md";
import DefaultUserImg from "../../Assets/Images/user-profile.png";
import { IoSettingsOutline } from "react-icons/io5";
import { SubscribeToDetails } from "../../Config/interface.config";
import { Capitalize } from "../../Utils";
import { useNavigate } from "react-router-dom";
import moment from "moment";

interface Props {
  creator: SubscribeToDetails;
}

const UserMemberShip = ({ creator }: Props) => {
  const navigate = useNavigate();

  const showProfile = (username: string) => {
    navigate(`/profile/${username}`);
  };
  return (
    <S.SubscribedUser>
      <div className="creator-details">
        <S.ProfileImage
          onClick={() => showProfile(creator.username)}
          src={creator?.image ? creator.image.fileURL : DefaultUserImg}
          alt="creator"
          crossOrigin="anonymous"
        />
        <div>
          <h3 className="flex items-center gap-2">
            {Capitalize(creator?.fullname)} <MdVerified />
          </h3>
          <p>@{creator?.username}</p>
          <div className="expire-details">
            <p>{`Expires on ${moment(creator?.expirationDate).format("DD/MM/YYYY")}`}</p>
            <IoSettingsOutline size={20} />
          </div>
        </div>
      </div>
    </S.SubscribedUser>
  );
};

export default UserMemberShip;
