import styled from "styled-components";
import { Link } from "react-router-dom";

export const ChantPasswordWrapper = styled.div`
  width: 100%;
  height: 100vh;
  overflow: auto;
  background-color: black;
`;

export const ChangePassword = styled.div`
  width: 1200px;
  max-width: 90%;
  margin: 0 auto;
  h1 {
    margin: 8rem 0 5rem 0;
    font-size: 2rem;
    font-weight: 500;

    @media screen and (max-width: 600px) {
      font-size: 1.8rem;
    }
  }
`;

export const AccountLinkWrapper = styled.div`
  display: flex;
  width: calc(100% - 136px);
  justify-content: center;
  flex-direction: column;
  margin: auto;
  gap: 2rem;

  .reset-password-form {
    display: flex;
    flex-direction: column;
    gap: 2rem;

    .submit-button {
      padding: 0.3rem 1rem;
    }
  }
`;

export const AccountLinkBox = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid rgb(255, 255, 255, 0.5);

  h3 {
    font-size: 1.6rem;
    color: white;
    font-weight: 500;
    @media screen and (max-width: 600px) {
      font-size: 1.3rem;
      font-weight: 400;
    }
  }

  svg {
    color: white;
    font-size: 1.7rem;
  }
`;
