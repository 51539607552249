import styled from "styled-components";

export const Profile = styled.div`
  width: 100%;
  height: 100%;
  max-width: 800px;
  margin: auto;
  padding: 1rem 0.7rem;
`;

export const UserDescription = styled.div`
  margin-top: 2rem;
  color: white;
  padding-bottom: 2rem;
  border-bottom: 0.2px solid #565656;
`;

export const PostWrapper = styled.div`
  padding-bottom: 1rem;
`;

export const ProfileEditWrapper = styled.div`
  width: 800px;
  max-width: 90%;
  padding: 2rem;
  margin: 2rem auto;
  position: relative;

  @media screen and (max-width: 768px) {
    padding: 2rem 0rem;
  }

  .profile-img-upload {
    span {
      top: 0;
    }
  }

  .profile-edit-form {
    textarea {
      border: none;
      outline: none;
      box-shadow: none !important;
      background-color: black;
      color: #ffffff;
      border-bottom: 1px solid #8f8f8f;
      border-radius: 0;
      font-size: 1;
      padding-left: 0;
      padding-top: 1rem;
      &::placeholder {
        color: rgba(255, 255, 255, 0.7);
      }
      &::active {
        box-shadow: none !important;
      }
    }
  }
`;

export const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  margin: auto;
  border-radius: 50%;
  overflow: hidden;
  object-fit: cover;
  background: white;
`;

export const ImageWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
`;

export const ImageButton = styled.button`
  width: fit-content;
  background: transparent;
  margin: auto;
  font-weight: 400;
`;

export const FormWrapper = styled.div`
  margin-top: 1rem;
  position: relative;
  bottom: 60px;
  z-index: 1111;

  .update-profile-btn {
    width: fit-content;
    padding: 0.2rem 1.5rem;
    border-radius: 50px;
    background: #323232;
    border: 1px solid #323232;
    color: #c1c1c1;
    cursor: pointer;
    transition: 0.2s;
    &:hover {
      background-color: #ffffff;
      color: #000000;
    }
  }
`;
