import React, { useEffect, useState } from "react";
import * as M from "./style";
import moment from "moment";
import userImg from "../../Assets/Images/user-profile-img.png";
import { useSelector } from "react-redux";
import { RootState } from "../../Redux/store";
import { Message } from "../../Config/interface.config";
import { messageScrollHandler } from "../../Utils";


interface Prop {
  activeChat: string;
}


const Messages: React.FC<Prop> = ({ activeChat }: Prop) => {
  const [messages, setMessages] = useState<any>({});
  const { messages: allMessages } = useSelector((state: RootState) => state.message);
  const { id } = useSelector((state: RootState) => state.user);

  function groupMessagesByDate(messages: Message[]) {
    return messages.reduce((acc: any, message: any) => {
      const date: string = new Date(message.createdAt).toLocaleDateString('en-GB');
      if (!acc[date]) {
        acc[date] = [];
      }
      acc[date].push(message);
      return acc;
    }, {});
  }

  const getMessageDate = (key: any) => {
    const moment_obj = moment();
    const currentDate = moment_obj.format("D-MM-YYYY");
    const previousDate = moment_obj.subtract("1", "days").format("D-MM-YYYY");
    const date = moment(key, "D-MM-YYYY").format("ddd, D MMM");

    if (previousDate === key) return "Yesterday";
    if (currentDate === key) return "Today";

    return date;
  };

  useEffect(() => {
    if (!allMessages.length) return;
    const response = groupMessagesByDate(allMessages);
    setMessages(response);
  }, [allMessages, activeChat])

  useEffect(() => {
    messageScrollHandler()
  }, [messages])

  return (
    <M.MessagesWrapper>
      {Object.keys(messages).map((key) => (
        <M.MessageBox className="text-white" key={`message-date-${key}`}>
          {
            messages[key].length ? <M.MessageDate> {getMessageDate(key)}</M.MessageDate> : <></>
          }
          <M.Messages>
            {messages[key].map((mess: Message, index: number) => {
              const remote: string = (mess.from !== id).toString();
              if (mess.subscriberId !== activeChat) return <></>
              return (
                <M.TextMessageWrapper remote={remote}>
                  <M.ProfileImg src={userImg} />
                  <M.Message key={`${key}-message-${index}`} remote={remote}>
                    <M.Text>
                      {mess.text}
                    </M.Text>
                    <M.Time remote={remote}>{moment(mess.createdAt).format('HH:MM')}</M.Time>
                  </M.Message>
                </M.TextMessageWrapper>
              );
            })}
          </M.Messages>
        </M.MessageBox>
      ))}
    </M.MessagesWrapper>
  );
};

export default Messages;
