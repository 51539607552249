import React, { useEffect, useState } from "react";
import * as A from "./styled";
import { FaAngleRight } from "react-icons/fa6";
import * as interFace from "../../Config/interface.config";
import { FaArrowLeft } from "react-icons/fa6";
import { isCreatorRole, logout } from "../../Config/common.config";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../Redux/store";
import { getUserDetails } from "../../Redux/slices/userSlice";

const Account: React.FC = () => {
  const navigate = useNavigate();
  const dispatch: AppDispatch = useDispatch();
  const user = useSelector((state: RootState) => state.user);

  const [links, setLinks] = useState<interFace.LinkInterface[]>([
    {
      title: "Contact and Support",
      url: "mailto:business@fanxo.club",
    },
    {
      title: "Edit Profile",
      url: "/profile/edit",
    },
    {
      title: "Change Password",
      url: "/password/change",
    },
    {
      title: "Payment and Billing",
      url: "/membership",
    },
    {
      title: "Subscription",
      url: "/membership/pricing",
    },
    {
      title: "Sign Out",
      url: window.location.pathname,
    },
  ]);

  useEffect(() => {
    dispatch(getUserDetails());
  }, []);

  useEffect(() => {
    if (!isCreatorRole(user.role) && links.length === 6) {
      const ids = [0, 1, 2, 5];

      const userLinks = links.filter((_, index) => ids.includes(index));
      userLinks.splice(userLinks.length - 1, 0, {
        title: "Membership",
        url: "/user-membership",
      });

      setLinks(userLinks);
    }
  }, [user]);

  return (
    <A.Account>
      <h1 className="text-white font-noto flex items-center gap-5 md:gap-10">
        <span className="cursor-pointer" onClick={() => navigate("/home")}>
          <FaArrowLeft size={30} />
        </span>
        <span>Account</span>
      </h1>
      <A.AccountLinkWrapper>
        {links.map((link, index) => (
          <A.AccountLinkBox
            onClick={() => {
              if (link.title === "Sign Out") logout();
            }}
            key={`account-link-${index}`}
            to={link.url}
          >
            <h3>{link.title}</h3>
            <FaAngleRight />
          </A.AccountLinkBox>
        ))}
      </A.AccountLinkWrapper>
    </A.Account>
  );
};

export default Account;
