import React, { useState, Dispatch, useEffect, useRef } from "react";
import RectImg from "./rect-img.png";
import AddIcon from "./add-icon.png";
import { IoClose } from "react-icons/io5";
import { CommonButton } from "../../../common-styles";
import { message, Upload } from "antd";
import UploadGallery from "../../../Assets/Images/UploadGallery.svg";
import { TextAreaCount } from "../../../Modals/styled";
import type { UploadProps } from "antd";
import * as API from "../../../API/path";
import toast from "react-hot-toast";
import { Formik, Form, Field, ErrorMessage } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { AppDispatch, RootState } from "../../../Redux/store";
import { createNewPost } from "../../../Redux/slices/postSlice";
import * as Interface from "../../../Config/interface.config";
import { isFileVideo } from "../../../Config/common.config";
import FanxoPlayBtnImg from "../../../Assets/Images/fanxo-play-btn.png";
import VideoPlayer from "../VideoHandler";
import DefaultUserImg from "../../../Assets/Images/user-profile.png";

interface Props {
  fileList: { fileURL: string; fileId: string }[];
  setFileList: Dispatch<
    React.SetStateAction<{ fileURL: string; fileId: string }[]>
  >;
  step: number;
  setStep: Dispatch<React.SetStateAction<number>>;
  onHide: () => void;
}

const StepOne = ({ fileList, setFileList, setStep, onHide }: Props) => {
  const user = useSelector((state: RootState) => state.user);
  const { id: user_id } = user;
  const dispatch: AppDispatch = useDispatch();
  const [selectedImage, setSelectedImage] = useState<string>("");
  const fileUploadRef: any = useRef();

  interface NewPostFormData {
    description: string;
  }

  // Define initial form values
  const initialValues: NewPostFormData = {
    description: "",
  };

  const validationSchema = Yup.object().shape({
    description: Yup.string().max(
      2000,
      "Caption cannot be more than 2000 characters"
    ),
  });

  const [disableFileUpload, setDisableFileUpload] = useState<boolean>(false);

  const props: UploadProps = {
    name: "file",
    action: `${process.env.REACT_APP_API_ENDPOINT}${API.fileUpload.url}`,
    headers: {
      authorization: "authorization-text",
    },
    onChange(info) {
      if (info.file.status === "done") {
        const fileURL = info.file.response.fileURL;
        const fileId = info.file.response._id;
        if (typeof fileURL === "string") {
          setFileList([...fileList, { fileId, fileURL }]);
        }

        message.success(`Image file uploaded successfully`);
      } else if (info.file.status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
  };

  const handleSubmit = (data: NewPostFormData) => {
    if (!user_id || typeof user_id !== "string") {
      toast.error("Invalid user.");
      return;
    }

    const postData: Interface.Post = {
      text: data.description,
      creator: user_id,
      files: fileList.map((file) => file.fileId),
      likeCount: [],
      comments: [],
      tips: [],
    };

    dispatch(createNewPost(postData)).then(() => onHide());
  };

  const removePostImage = (image: string) => {
    setFileList(fileList.filter((file) => file.fileURL !== image));
  };

  useEffect(() => {
    if (fileList.length > 0) {
      const isFileExists = fileList.find(
        (file) => file.fileURL === selectedImage
      );
      if (!isFileExists) {
        setSelectedImage(fileList[0].fileURL);
      }
    } else setSelectedImage("");
  }, [fileList, selectedImage]);

  return (
    <div>
      <Formik
        initialValues={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
      >
        {({ values }) => (
          <Form>
            <div className={`${fileList.length > 0 ? "hidden" : "block"}`}>
              <Upload.Dragger
                {...props}
                height={450}
                showUploadList={false}
                className={`block upload-modal`}
                disabled={disableFileUpload}
                listType="picture-card"
              >
                <img
                  src={UploadGallery}
                  width={120}
                  className="mx-auto"
                  alt="fanxo"
                  crossOrigin="anonymous"
                />
                <p
                  ref={fileUploadRef}
                  className="ant-upload-text mt-0 !text-white"
                >
                  Drag and Photos videos here
                </p>

                <div className="mt-10 max-w-[350px] mx-auto">
                  <CommonButton type="button" variant="tertary">
                    Select From Computer
                  </CommonButton>
                </div>
              </Upload.Dragger>
              <div className="mt-5">
                <CommonButton
                  type="button"
                  variant="tertary"
                  style={{ maxWidth: "350px", margin: "auto" }}
                  className="select-text-only-button"
                  onClick={() => setStep(2)}
                  onMouseEnter={() => setDisableFileUpload(true)}
                  onMouseLeave={() => setDisableFileUpload(false)}
                >
                  Select Text Only Post
                </CommonButton>
              </div>
            </div>

            {fileList.length && (
              <>
                <div className="relative w-full md:flex bg-black block">
                  <div className="relative flex-1">
                    {isFileVideo(selectedImage) ? (
                      <div className="w-full object-cover h-[700px] relative z-0">
                        <VideoPlayer url={selectedImage} />
                      </div>
                    ) : (
                      <img
                        src={selectedImage}
                        crossOrigin="anonymous"
                        className="w-full object-cover h-[400px] md:h-[700px]"
                      />
                    )}

                    <div className="flex gap-0 flex-wrap items-center absolute w-[95%] mx-auto z-10 left-1/2 bottom-10 -translate-x-1/2">
                      {fileList.map((postImage) => (
                        <div className="w-28 h-28 relative">
                          <div
                            className="w-full h-full absolute bg-black opacity-50"
                            onClick={() => setSelectedImage(postImage.fileURL)}
                          ></div>
                          <img
                            src={
                              isFileVideo(postImage.fileURL)
                                ? FanxoPlayBtnImg
                                : postImage.fileURL
                            }
                            crossOrigin="anonymous"
                            className="w-full h-full object-cover"
                          />
                          <IoClose
                            onClick={() => removePostImage(postImage.fileURL)}
                            color="#ffffff"
                            size={25}
                            strokeWidth={0.5}
                            className="absolute top-1 right-1 cursor-pointer"
                          />
                        </div>
                      ))}
                      {/* Add More Images */}
                      <div
                        onClick={() => fileUploadRef?.current.click()}
                        className="w-28 h-28 bg-black relative opacity-50 flex items-center justify-center cursor-pointer"
                      >
                        <img
                          src={RectImg}
                          className="opacity-65 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                          alt=""
                        />
                        <img
                          src={AddIcon}
                          className="opacity-65 absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
                          alt=""
                        />
                      </div>
                    </div>
                  </div>
                  <div className="md:max-w-[35%] md:w-[400px] bg-black px-5 pb-10 md:pb-0">
                    <div className="gap-4 items-center mt-4 relative">
                      <div className="flex gap-5 items-center">
                        <img
                          src={
                            user.image?.fileURL
                              ? user.image?.fileURL
                              : DefaultUserImg
                          }
                          crossOrigin="anonymous"
                          alt="fanxo user"
                          className="w-10 h-10 rounded-full object-cover"
                        />
                        <h5 className="text-white text-base font-inter">
                          {user.fullname ?? "User Fullname"}
                        </h5>
                      </div>

                      <Field
                        rows={4}
                        as="textarea"
                        name="description"
                        className="w-full mt-3 text-lg py-2 bg-[#000000] font-inter text-white rounded resize-none placeholder:text-white/70"
                        placeholder="Caption it..."
                      />

                      <TextAreaCount className="text-right font-inter">
                        {values.description.length}/2000
                      </TextAreaCount>
                      <ErrorMessage
                        name="description"
                        component="p"
                        className="text-red-600 mt-2"
                      />

                      <CommonButton variant="tertary" className="w-full mt-5">
                        Share
                      </CommonButton>
                    </div>
                  </div>
                </div>
              </>
            )}
          </Form>
        )}
      </Formik>
    </div>
  );
};

export default StepOne;
